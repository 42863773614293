<template>
    <div>
        <div class="popover-overlay"
            :class="{ 'd-none': !props.isFilterPopoverVisible, 'd-block': props.isFilterPopoverVisible }"
            :style="props.style">
            <div class="popover-content">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    isFilterPopoverVisible: {
        type: Boolean,
        required: true
    },
    style: {
        type: Object,
        required: true
    }
})
</script>