<template>
    <div class="actions-description">
        You may attach one document at a time using the form below
    </div>
    <div class="actions-form">
        <form @submit.prevent="submitDocumentForm()" v-if="formDocument">
            <div class="row p-2">
                <div class="col-3">
                    Document Name <span class="required">*</span>
                </div>
                <div class="col-7">
                    <input id="candidate-document-name" type="text" maxlength="255" v-model="formDocument.document_name"
                        class="cbFormTextField cbFormTextField-xl" required>
                </div>
            </div>
            <div class="row p-2">
                <div class="col-3">
                    Link
                </div>
                <div class="col-7">
                    <input id="candidate-document-link" type="url" maxlength="2048" v-model="formDocument.link"
                        pattern="http(s)?://.+" class="cbFormTextField cbFormTextField-xl">
                </div>
            </div>
            <div class="row p-2">
                <div class="col-3">
                    Upload
                </div>
                <div class="col-7">
                    <input id="candidate-document-file-input" type="file" ref="fileInput"
                        class="cbFormTextField cbFormTextField-xl" @change="handleFileInput">
                </div>
            </div>
            <div class="row p-2">
                <div class="col-3">
                    Note
                </div>
                <div class="col-7">
                    <textarea id="candidate-document-notes" v-model="formDocument.notes" :maxlength="MAX_NOTE_LENGTH"
                        class="textwrapper"></textarea>
                </div>
            </div>
            <div class="row p-2">
                <div class="offset-3 col-7 d-flex">
                    <loading-icon v-if="formLoading" />
                    <button class="searchButton" type="submit" :disabled="formLoading">Submit</button>
                </div>
            </div>
        </form>

        <div class="mt-2" id="candidate-document-list"></div>
    </div>

    <ModalDialog v-if="showDeleteDocumentDialog" title="Delete Document" :close="() => { }">
        <div id="delete-document">
            Are you sure you want to delete this document?
            <br>
            <br>
            This action cannot be undone.
        </div>
        <template #footer>
            <loading-icon v-if="deleteDocumentLoading" />
            <button @click="deleteDocument()" :disabled="deleteDocumentLoading"
                class="searchButton removeButton">Confirm</button>
            <button @click="closeDeleteDocumentDialog()" class="searchButton clearButton">Close</button>
        </template>
    </ModalDialog>
    <Galleria v-model:visible="isGalleriaVisible" v-model:activeIndex="activeIndex" :value="galleriaItems"
        :numVisible="7" :thumbnailsPosition="'bottom'" containerStyle="max-width: 60%" :circular="true"
        :fullScreen="true" :showItemNavigators="true">
        <template #item="slotProps">
            <img :src="slotProps.item.image.src" :alt="slotProps.item.image.alt" />
        </template>
        <template #thumbnail="slotProps">
            <img :src="slotProps.item.image.src" :alt="slotProps.item.image.alt" style="display: block" />
        </template>
        <template #caption="slotProps">
            <a :href="slotProps.item.image.src" target="_blank" class="text-white">
                <span class="fa fa-download me-1"></span> Download | {{ slotProps.item.filename }}
            </a>
        </template>
    </Galleria>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, onUnmounted, watch, onBeforeUnmount } from "vue"
import type { CandidateFormDocuments, CandidateDocumentList } from "@/helpers/interface/candidates"
import {
    MAX_NOTE_LENGTH,
    galleriaThumbnailButtons,
    getApiErrorMessage,
    getQueryParams,
    isImageFile,
    galleriaKeyboardSupport
} from "@/helpers/common";
import { useAPI } from "@/helpers/services/api";
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import { useRoute } from "vue-router"
import { toast } from "@/helpers/toast";
import type { AxiosError } from "axios";
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import ModalDialog from "@/components/Shared/ModalDialog.vue";
import { createTabulator, destroyTabulator } from "@/helpers/true-tabulator";
import type { ColumnDefinition, CellComponent } from "tabulator-tables";
import type { TabulatorFull as Tabulator } from 'tabulator-tables'
import Galleria from 'primevue/galleria';
import dayjs from "dayjs";

const route = useRoute()
const api = useAPI()

const tru_id = route?.params?.tru_id as string
const formLoading = ref(false)
const deleteDocumentLoading = ref(false)
const fetchDocumentListLoading = ref(false)
const fileInput = ref<HTMLInputElement | null>(null);
const storeCandidateDetails = useCandidateDetailsStore();
const showDeleteDocumentDialog = ref<boolean>(false)
const selectedDeleteDocument = ref<string>("")
const formDocumentInitialState = {
    document_name: "",
    link: "",
    file: null,
    notes: "",
}
const galleriaItems = ref<any[]>([])
const activeIndex = ref(0)
const isGalleriaVisible = ref(false)
const imageClick = (file_name: string) => {
    isGalleriaVisible.value = true
    activeIndex.value = galleriaItems.value?.findIndex(doc => doc.filename === file_name) as number
    galleriaThumbnailButtons()
    setTimeout(() => galleriaKeyboardSupport('attach', handleKeyDown), 500)
}

const handleKeyDown = (event: KeyboardEvent) => {
    const key = event.key
    switch (key) {
        case 'ArrowLeft':
            if (activeIndex.value === 0) {
                activeIndex.value = galleriaItems.value.length - 1
            }
            else activeIndex.value -= 1
            break;
        case 'ArrowRight':
            if (activeIndex.value === galleriaItems.value.length - 1) {
                activeIndex.value = 0
            }
            else activeIndex.value += 1
            break
        case 'Escape':
            isGalleriaVisible.value = false
            break
        default:
            break
    }
}

let tabulator: Tabulator | null;
const tableColumns = ref<ColumnDefinition[]>([
    {
        title: "Document Name",
        field: "document_name",
        width: "22vh",
    },
    {
        title: "Link",
        field: "link",
        width: "13vh",
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateDocumentList
            if (data.link) return `<a href="${data.link}" target="_blank">Link</a>`
            return "-"
        }
    },
    {
        title: "Note",
        field: "notes",
        width: "18vh",
    },
    {
        title: "File",
        field: "created_at",
        width: "17vh",
        headerSort: false,
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateDocumentList
            const preview = document.createElement("a")
            preview.classList.add("file-name-link")

            if (data.download_url) {
                if (isImageFile(data.file_name)) {
                    preview.href = "javascript:;"
                    preview.onclick = () => imageClick(data.file_name)
                    preview.innerHTML = "Preview"
                }
                else {
                    preview.href = data.download_url
                    preview.target = "_blank"
                    preview.innerHTML = "Download"
                }
                return preview
            }
            return "-"
        }
    },
    {
        title: "&nbsp;",
        field: "updated_at",
        width: "25vh",
        headerSort: false,
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateDocumentList
            const actionParent = document.createElement("div")
            const documentID = data.id

            const removeButton = document.createElement("a")
            removeButton.innerHTML = "Delete"
            removeButton.classList.add("searchButton", "removeButton", "text-white")
            removeButton.setAttribute("id", `remove-btn-${documentID}`)
            removeButton.href = "javascript:;"
            removeButton.addEventListener("click", () => {
                const id = `remove-btn-loading-${documentID}`

                const removeButtonLoading = document.createElement("div")
                removeButtonLoading.classList.add("remove-btn")
                removeButtonLoading.classList.add("d-inline")
                removeButtonLoading.classList.add("d-none")
                removeButtonLoading.setAttribute("id", id)
                removeButtonLoading.innerHTML = '<span class="fa fa-spinner fa-spin"></span>'

                if (!document.getElementById(id)) actionParent.append(removeButtonLoading)
                showDeleteDocumentDialogConfirmation(documentID)

            })
            actionParent.append(removeButton)
            return actionParent
        },
    }
])

const loadFailureHandler = (error: AxiosError) => {
    const message = getApiErrorMessage(error)
    toast.error(message)
}

const formDocument = ref<CandidateFormDocuments>({
    ...formDocumentInitialState
})

const documentList = computed(() => (storeCandidateDetails.getDocumentList))
watch(() => documentList.value, async (documentList) => {
    // Filter by image files
    const dataImages = documentList?.filter(item => isImageFile(item.file_name))
    if (!dataImages?.length) return
    let images = []
    for (const item of dataImages) {
        const img = new Image()
        img.src = item.download_url
        img.alt = item.file_name
        const items = {
            image: img,
            filename: item.file_name,
            expirationDate: getQueryParams(item.download_url).get("Expires") || "",
        }
        images.push({
            ...items,
            url: item.download_url
        })
    }
    galleriaItems.value = images
})

const handleFileInput = (event: any) => {
    const file = event.target.files[0]
    formDocument.value.file = file
}

const clearFileInput = () => {
    if (fileInput.value) {
        fileInput.value.type = ''; // Change the type of the file input
        fileInput.value.type = 'file'; // Restore the type to clear the file attachment
    }
};

const submitDocumentForm = async () => {
    const formData = new FormData()

    if (!formDocument.value.file && !formDocument.value.link) {
        toast.error("Please provide either a Link or File attachment")
        return
    }

    formData.append('file', formDocument.value.file || "")
    formData.append('document_name', formDocument.value.document_name)
    formData.append('link', formDocument.value.link || "")
    formData.append('notes', formDocument.value.notes || "")

    formLoading.value = true

    await api.post(`/taxroll/upload/${tru_id}`, formData)
        .then(async () => {
            await storeCandidateDetails.fetchDocumentList(tru_id, loadFailureHandler)
            await storeCandidateDetails.fetchHistory(tru_id as string, loadFailureHandler)
            toast.success("New document file added")
            formDocument.value = { ...formDocumentInitialState }
            clearFileInput()
        })
        .catch((error: AxiosError) => {
            toast.error(getApiErrorMessage(error))
        })
        .finally(() => {
            formLoading.value = false;
        })
}

const deleteDocument = async () => {
    deleteDocumentLoading.value = true
    try {
        await storeCandidateDetails.deleteDocument(selectedDeleteDocument.value)
        await storeCandidateDetails.fetchDocumentList(tru_id, loadFailureHandler)
        await storeCandidateDetails.fetchHistory(tru_id, loadFailureHandler)
        toast.success("Document has been removed")
    } catch (error: unknown) {
        const err = error as AxiosError
        loadFailureHandler(err)
    }
    deleteDocumentLoading.value = false
    closeDeleteDocumentDialog()
}

const showDeleteDocumentDialogConfirmation = (documentId: string) => {
    showDeleteDocumentDialog.value = true
    selectedDeleteDocument.value = documentId
}

const closeDeleteDocumentDialog = () => {
    showDeleteDocumentDialog.value = false
    selectedDeleteDocument.value = ""
}

const buildTabulator = async (isLoading: boolean) => {
    tabulator = await createTabulator("#candidate-document-list", {
        data: documentList.value,
        placeholder: "Any documents attached will appear below",
        columns: tableColumns.value,
        initialSort: [
            { column: "document_name", dir: "asc" },
        ]
    }, isLoading)
}

const checkExpiration = async () => {
    const now = dayjs().unix()
    if (galleriaItems.value.length <= 0) return
    if (now > galleriaItems.value[0]?.expirationDate) {
        await storeCandidateDetails.fetchDocumentList(tru_id, loadFailureHandler)
    }
}

const intervalId = ref<number | null>(null)
const startExpirationCheck = () => {
    if (intervalId.value === null) {
        intervalId.value = window.setInterval(checkExpiration, 10000) // Check every 10 seconds
    }
}

const stopExpirationCheck = () => {
    if (intervalId.value !== null) {
        clearInterval(intervalId.value)
        intervalId.value = null
    }
}

onMounted(async () => {
    await storeCandidateDetails.fetchDocumentList(tru_id, loadFailureHandler)
    await buildTabulator(fetchDocumentListLoading.value)
    startExpirationCheck()
})

onUnmounted(() => {
    destroyTabulator(tabulator)
    stopExpirationCheck()
    galleriaKeyboardSupport('detach', handleKeyDown)
})

onBeforeUnmount(() => galleriaKeyboardSupport('detach', handleKeyDown))

const isLoading = computed(() => {
    return fetchDocumentListLoading.value || deleteDocumentLoading.value || formLoading.value;
});

watch(() => isLoading.value, async (isLoading) => {
    await buildTabulator(isLoading)
})

</script>

<style>
@import "@/assets/candidate-details.css";
</style>
