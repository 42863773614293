<template>
    <div class="actions-description">
        The TrueRoll Mail Module makes it super easy to send and track letters that you might send
        to taxpayers (questionnaire, notice of denial, liens, back taxes, etc). To add a letter,
        select the Mail Template when you assign a Candidate to a Questionnaire or Unqualified
        queue.
    </div>
    <div class="actions-form">
        <template v-if="formVisible">
            <CandidateMailUpdateForm v-model:item="editItem" />
            <div class="offset-3 col-6 d-flex pt-2">
                <loading-icon v-if="formSaving" />
                <button type="button" class="searchButton" :disabled="formSaving"
                    @click="updateMailClick">Update</button>
                <button type="button" class="searchButton" :disabled="formSaving"
                    @click="formVisible = false">Back</button>
            </div>
        </template>

        <template v-else>
            <div class="text-center p-2" v-if="mailList?.length === 0 && !fetchMailListLoading">
                No Records Found.
            </div>
            <div v-else>
                <loading-icon v-if="fetchMailListLoading" />
            </div>
            <table class="table table-striped" v-if="mailList?.length !== 0 && !fetchMailListLoading">
                <thead class="table-secondary">
                    <tr>
                        <th width="40%">Status</th>
                        <th width="30%">Details</th>
                        <th width="30%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="mail in mailList" :value="mail.mail_id" :key="mail.mail_id">
                        <td width="30%">
                            <a href="javascript:;" @click="mailItemClick(mail)">{{ getStatusText(mail) }}</a>
                        </td>
                        <td width="40%">
                            <div>
                                <p>
                                    <strong>Mail ID: </strong>
                                    <span>{{ mail.mail_id }}</span>
                                </p>
                                <p>
                                    <strong>Template: {{ mail.mail_template }}</strong>
                                </p>
                                <p>
                                    <strong>Created: </strong>
                                    <time datetime="{{ mail.created_at }}">{{ getDisplayDate(mail.created_at) }}</time>
                                </p>
                                <p>
                                    <strong>Mail Address: </strong>
                                    <span>{{ mail.mail_address }}</span>
                                </p>
                                <p>
                                    <strong>Expiry: {{ getDisplayDate(mail.expires_on) }}</strong>
                                    <span></span>
                                </p>
                            </div>
                        </td>
                        <td width="30%">
                            <button class="searchButton removeButton" :disabled="removeMailLoading"
                                @click="removeMailFromCandidate(mail.mail_id)">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </template>

    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue"
import { useAPI } from "@/helpers/services/api";
import { useRoute } from "vue-router"
import { toast } from "@/helpers/toast";
import type { CandidateFormMail } from "@/helpers/interface/candidates"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import CandidateMailUpdateForm from "@/components/Shared/FormAction/CandidateMailUpdateForm.vue"
import { mailStatusOptions as statusOptions } from "@/helpers/dropdownOptions";
import type { CandidateFormMailUpdate } from "@/helpers/interface/candidates";
import { getApiErrorMessage, getDisplayDate } from "@/helpers/common";
import type { AxiosError } from "axios";
import { useCandidateDetailsStore } from "@/stores/candidateDetails";

const route = useRoute()
const api = useAPI()
const storeCandidateDetails = useCandidateDetailsStore();

const fetchMailListLoading = ref(false)
const removeMailLoading = ref(false)
const formVisible = ref(false)
const formSaving = ref(false)
const mailList = computed((): CandidateFormMail[] => (storeCandidateDetails.getMailList))
const editItem = ref<CandidateFormMail>({} as CandidateFormMail)
const tru_id = route?.params?.tru_id as string

const loadFailureHandler = (error: AxiosError) => {
    const message = getApiErrorMessage(error)
    toast.error(message)
}

let removeMailFromCandidate = async (mail_id: CandidateFormMail['mail_id']) => {
    removeMailLoading.value = true;
    try {
        await api.patch(`/taxroll/mails/${tru_id}/delete`, { mail_id });
        toast.success(`Mail successfully deleted`)
        await storeCandidateDetails.fetchMailList(tru_id, loadFailureHandler)
        await storeCandidateDetails.fetchHistory(tru_id, loadFailureHandler)
    }
    catch (error: any) {
        toast.error(getApiErrorMessage(error))
    }
    finally {
        removeMailLoading.value = false;
    }
}

let mailItemClick = (mail: CandidateFormMail) => {
    editItem.value = { ...mail, mailed_at: mail.mailed_at?.split('T')[0] } as CandidateFormMail
    formVisible.value = true
}

let updateMailClick = async () => {
    const item = editItem.value as CandidateFormMail
    formSaving.value = true

    const data: CandidateFormMailUpdate = {
        id: item.id,
        mail_to: item.mail_to,
        mail_address: item.mail_address,
        mail_address2: item.mail_address2,
        mailed_at: item.mailed_at ? new Date(item.mailed_at).toISOString() : null,
        mail_status: item.mail_status,
        note_for_template: item.note_for_template
    };

    await api.patch(`/taxroll/mails/${tru_id}`, data)?.then(async () => {
        toast.success(`Mail successfully updated!`)
        formVisible.value = false
        await storeCandidateDetails.fetchMailList(tru_id, loadFailureHandler)
        await storeCandidateDetails.fetchHistory(tru_id, loadFailureHandler)
    })?.catch((error: AxiosError) => {
        toast.error(getApiErrorMessage(error))
    })?.finally(() => {
        formSaving.value = false;
    })
}

const getStatusText = (mail: CandidateFormMail): string => {
    const option = statusOptions.find(o => o.value === mail.mail_status)
    return option ? option.text : ""
}

onMounted(() => {
    storeCandidateDetails.fetchMailList(tru_id, loadFailureHandler)
})

</script>

<style>
@import "@/assets/candidate-details.css";
</style>
