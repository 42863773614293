<template>
    <div class="public-button-container text-center">
        <button :disabled="formLoading" type="submit" class="public-button public-button-submit text-bigger">
            Submit Application
        </button>
        <loading-icon class="m-2" v-if="formLoading" />
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useApplicationForm } from "@/stores/applicationForm"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue"

const storeApplicationForm = useApplicationForm()
const formLoading = computed(() => storeApplicationForm.getOrSetFormLoading())

</script>