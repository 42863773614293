import { ref, computed } from "vue"
import { defineStore } from "pinia";
import { useAPI } from "@/helpers/services/api"
import { useAuth0 } from '@auth0/auth0-vue';
import { sortObjectsByKey, getApiErrorMessage } from "@/helpers/common"
import type { Customer, UserRoles, Labels, Auth0User } from '@/helpers/interface/admin-page';
import { ClaimsFields, Roles } from "@/helpers/roles"

export const useAdminDetails = defineStore("adminDetails", () => {

    const api = useAPI()
    const { user } = useAuth0()
    const vettingApplications = ref([])
    const allAvailableRoles = ref<UserRoles[]>([])
    const customerList = ref({
        hasAccess: false,
        data: <Customer[]>[]
    })
    const labelsList = ref<Labels[]>([])
    const userList = ref<Auth0User[]>([])
    const internalSettings = ref<{ parameter: string, value: any }[]>([])

    const fetchCustomersList = async (errorHandler: Function) => {
        api.get("/customers/?listed=true")
            ?.then(response => {
                const items = response.data

                // sort by customer name
                sortObjectsByKey(items, "name")

                customerList.value = {
                    hasAccess: user.value[ClaimsFields['roles']].includes(Roles['trueroll_employee']),
                    data: items
                }
            })
            ?.catch(error => errorHandler(error))
    }

    const fetchAllAvailableRoles = async (errorHandler: Function) => {
        await api.get("/users/roles/")
            ?.then(response => {
                const roles = (response.data?.roles || []) as UserRoles[]
                allAvailableRoles.value = roles
            })
            ?.catch(error => errorHandler(error))

    }

    const fetchLabelsList = async (errorHandler: Function) => {
        await api.get("/labels/")
            ?.then(response => {
                labelsList.value = response.data
            })
            ?.catch(error => errorHandler(error))
    }

    const fetchUsersList = async (errorHandler: Function) => {
        await api.get(`/users/auth0/?include_roles=false&blocked=false`)
            ?.then(response => {
                const users = (response.data?.users || [])
                userList.value = users
            })
            ?.catch(error => {
                userList.value = []
                errorHandler(error)
            })
    }

    const fetchInternalSettings = async (errorHandler: Function) => {
        await api.get("/settings/internal")?.then(response => {
            internalSettings.value = response.data
        }).catch(error => {
            errorHandler(error)
        })
    }

    const fetchInternalSettingsItem = async (setting: string, errorHandler: Function | undefined = undefined) => {
        let settingsItem = null

        if (typeof errorHandler === "undefined") {
            errorHandler = (error: any) => {
                const message = getApiErrorMessage(error, { "featureName": "Setting" })
                console.error(message)
            }
        }
        try {
            const response = await api.get(`/settings/internal/${setting}`)
            settingsItem = response.data
        }
        catch (error: unknown) {
            errorHandler(error)
        }

        return settingsItem
    }

    const getCustomerList = computed(() => customerList.value)
    const getVettingApplications = computed(() => vettingApplications.value)
    const getAllAvailableRoles = computed(() => allAvailableRoles.value)
    const getAllLabels = computed(() => labelsList.value)
    const getUsersList = computed(() => userList.value)
    const getInternalSettings = computed(() => internalSettings.value)

    return {
        customerList,
        labelsList,
        allAvailableRoles,
        userList,
        fetchCustomersList,
        fetchAllAvailableRoles,
        fetchLabelsList,
        fetchUsersList,
        fetchInternalSettings,
        fetchInternalSettingsItem,
        getCustomerList,
        getUsersList,
        getVettingApplications,
        getAllAvailableRoles,
        getAllLabels,
        getInternalSettings
    }
})