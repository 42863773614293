<template>
	<div class="card-cl-history" style="height: 500px; width: 100%">
		<div class="card-body">
			<div id="history-list"></div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, watch, onUnmounted, onMounted, computed } from 'vue'
import { ucfirst, sanitizeHTML, updateTypes } from '@/helpers/common'
import type { TabulatorFull as Tabulator } from 'tabulator-tables'
import type { HistoryAPIResponse } from "@/helpers/interface/candidates"
import dayjs from 'dayjs'
import type { ColumnDefinition, CellComponent } from "tabulator-tables"
import { createTabulator, destroyTabulator } from "@/helpers/true-tabulator"
import { useCandidateDetailsStore } from "@/stores/candidateDetails";

const store = useCandidateDetailsStore();
const history = computed((): HistoryAPIResponse[] => store.getHistory)
let tabulator: Tabulator | null
const tableColumns = ref<ColumnDefinition[]>([
	{
		title: "Update Type",
		field: "queue",
		headerSort: false,
		width: "20%",
		formatter: function (cell: CellComponent) {
			const data = cell.getData() as HistoryAPIResponse
			return updateTypes[data.update_type_id]
		}
	},
	{
		title: "Details",
		width: "44%",
		headerSort: false,
		field: "queue",
		formatter: function (cell: CellComponent) {
			const data = cell.getData() as HistoryAPIResponse
			return sanitizeHTML(data.update_details || "")
		}
	},
	{
		title: "User",
		width: "20%",
		headerSort: false,
		field: "created_by",
		formatter: function (cell: CellComponent) {
			const data = cell.getData() as HistoryAPIResponse
			return ucfirst(data.created_by)
		}
	},
	{
		title: "Date",
		width: "15%",
		headerSort: false,
		field: "created_at",
		formatter: function (cell: CellComponent) {
			const data = cell.getData() as HistoryAPIResponse
			const created_at = data.created_at
			return dayjs((created_at)).format("M/D/YYYY")
		}
	}
])

const loadTabulator = async (data: HistoryAPIResponse[] | undefined) => {
	tabulator = await createTabulator("#history-list", {
		data: data,
		columns: tableColumns.value,
		initialSort: [
			{ column: "created_at", dir: "desc" },
		],
		pagination: false,
		placeholder: "No records found.",
	})
}
onMounted(() => loadTabulator(history.value))
onUnmounted(() => destroyTabulator(tabulator))
watch(() => history.value, async (data) => {
	await loadTabulator(data)
});
</script>

<style>
@import "@/assets/candidate-details.css";

.card-cl-history {
	width: 100%;
	vertical-align: top !important;
}

.card-cl-history .tabulator-row {
	font-size: var(--general-text-size);
}

.card-cl-history .tabulator-cell {
	white-space: normal !important;
}

.card-cl-history .tabulator-row .tabulator-cell {
	padding-left: 0px !important;
	padding-right: 0px !important;
}
</style>