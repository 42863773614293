<template>
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a :class="['nav-link', 'markdown-editor-activator', editorVisible?'active':'']" aria-current="page" @click="editorVisible = true">Markdown Editor</a>
    </li>
    <li class="nav-item">
      <a :class="['nav-link', 'markdown-preview-activator', previewVisible?'active':'']" @click="editorVisible = false">Preview</a>
    </li>
  </ul>
  <div v-if="editorVisible" class="markdown-editor-container">
    <textarea
      class="form-control"
      :rows="numRows"
      v-model="value"
    ></textarea>
    <a href="https://www.markdownguide.org/cheat-sheet/" target="_blank" class="float-end fw-lighter text-muted small">Markdown Cheat Sheet</a>
  </div>
  <div v-if="previewVisible" class="markdown-preview">
    <div v-html="renderedHtml"></div>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import { ref } from 'vue'
import {marked} from "marked";

const props = defineProps({
  modelValue: {
    type: String,
    required: false,
    default: ''
  },
  numRows: {
    type: Number,
    required: false,
    default: 3
  }
})

const emit = defineEmits(['update:modelValue'])
const renderedHtml = computed(() => marked(props.modelValue))
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
const editorVisible = ref(true)
const previewVisible = computed(() => !editorVisible.value)

</script>

<style scoped>
.markdown-preview {
  padding: .375rem .75rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  min-height: calc(1.5em + .75rem + 2px);
  line-height: 1.5;
}
</style>
