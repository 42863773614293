<template>
<div class="row">
  <div class="d-flex">
      <button class="searchButton cbFormTextField-sm mt-2" type="button"
              @click="SendToWindow($router.resolve({name: 'onboardingConfigs'}).href)">
        Config List
    </button>
      <button class="searchButton cbFormTextField-sm mt-2" type="button"
              @click="SendToWindow($router.resolve({name: 'onboardingConfig', params: { id: 'new' }}).href)">
        New Config
    </button>
  </div>
  <div class="d-flex">
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
            @click="SendToWindow($router.resolve({name: 'ingestions'}).href)">
        Ingestions
    </button>
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
            @click="SendToWindow($router.resolve({ name: 'ingestion', params: { id: 'new' }}).href)">
        New Ingestion
    </button>
  </div>
  <div class="d-flex">
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
             @click="SendToWindow($router.resolve({name: 'dataRequests'}).href)">
        Data Requests
    </button>
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
            @click="SendToWindow($router.resolve({ name: 'dataRequest', params: { data_request_id: 'new' }}).href)">
        New Data Request
    </button>
  </div>
  <div class="d-flex">
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
             @click="SendToWindow('https://drive.google.com/drive/folders/1by1V1ZYeqkSIgn9cIKf2chijJJQkUu5U')">
        Customer Scorecards
    </button>
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
            @click="SendToWindow($router.resolve({ name: 'scorecard', params: { job_id: 'new' }}).href)">
        Generate Scorecard
    </button>
  </div>
  <div class="d-flex">
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
             @click="SendToWindow($router.resolve({ name: 'cleanup', params: { job_id: 'new' }}).href)">
        Finalize Refresh
    </button>
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
            @click="SendToWindow($router.resolve({ name: 'refill_taxroll', params: { job_id: 'new' }}).href)">
        Refill Taxroll
    </button>
  </div>
  <div class="d-flex">
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
             @click="SendToWindow($router.resolve({ name: 'generate_extracts', params: { job_id: 'new' }}).href)">
        Generate Extracts
    </button>
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
            @click="SendToWindow($router.resolve({ name: 'generate_observations', params: { job_id: 'new' }}).href)">
        Generate Observations
    </button>
  </div>
</div>
</template>

<script setup lang="ts">

const SendToWindow = (url: string) => {
  window.open(url, '_blank')
}

</script>

<style scoped>

</style>