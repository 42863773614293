<template>
    <section id="important-information" class="public-important-information">
        <h2 class="text-center">Important Information</h2>
        <div class="section-content public-info-container">
            <div class="public-info-column">

                <div class="public-info-item">
                    <div class="public-info-item-header">GENERAL INSTRUCTIONS</div>
                    <div class="public-info-content">
                        <p>This application is for claiming residence exemptions pursuant to Tax Code Sections
                            11.13, 11.131, 11.132, 11.133, 11.134 and 11.432. Certain exemptions
                            may also require Form 50-114-A. The exemptions apply only to property
                            that you own and occupy as your principal place of residence.</p>
                    </div>

                </div>

                <div class="public-info-item">
                    <div class="public-info-item-header">FILING INSTRUCTIONS</div>
                    <div class="public-info-content">
                        <p>File this form and all supporting documentation with the appraisal district
                            office in each county in which the property is located generally between Jan. 1 and
                            April 30 of the year for which the exemption is requested. Do not file this document with
                            the Texas Comptroller of Public Accounts. A directory with contact information
                            for appraisal district offices is on the Comptroller's website.</p>
                    </div>

                </div>

                <div class="public-info-item">
                    <div class="public-info-item-header">APPLICATION DEADLINES</div>
                    <div class="public-info-content">
                        <p>Generally, the completed application and required documentation is due no later
                            than April 30 of the year for which the exemption is requested.
                            The due date for persons age 65 or older; disabled; or partially disabled veterans with
                            donated homesteads to apply for the exemption is no later than the first
                            anniversary of the qualification date. A late application for a residence
                            homestead exemption may be filed up to two years after the deadline for filing
                            has passed. (Tax Code Section 11.431). A late application for residence
                            homestead exemption filed for a disabled veteran (not a surviving spouse) under
                            Tax Code sections 11.131 or 11.132 may be filed up to 5 years after the
                            delinquency date. Surviving spouse of a disabled veteran, who files under Tax
                            Code sections 11.131 or 11.132, may file up to two years after the delinquency
                            date, for a late application for residence homestead exemption. If the Chief
                            Appraiser grants the exemption(s), property owner does not need to reapply
                            annually, but must reapply if the Chief Appraiser requires it, unless seeking to
                            apply the exemption to property not listed in this application. Property owners
                            already receiving a general residence homestead exemption who turn age 65 in
                            that next year are not required to apply for age 65 or older exemption if
                            accurate birthdate information is included in the appraisal district records or
                            in the information the Texas Department of Public Safety provided to the
                            appraisal district under Transportation Code Section 521.049. (Tax Code Section
                            11.43(m))</p>
                    </div>

                </div>

                <div class="public-info-item">
                    <div class="public-info-item-header">REQUIRED DOCUMENTATION</div>
                    <div class="public-info-content">
                        <p>Attach a copy of property owner's driver license or state-issued personal
                            identification certificate. The address listed on the driver license or
                            state-issued personal identification certificate must correspond to the
                            property address for which the exemption is requested. Property owners who
                            reside in certain facilities or participate in a certain address confidentiality
                            program may be exempt from this requirement. The Chief Appraiser may waive the
                            requirements for certain active duty U.S. armed services members or their
                            spouses or holders of certain driver licenses. <strong>Heir property</strong> is property
                            owned by one or more individuals, where at least one owner claims the property
                            as a residence homestead, and the property was acquired by will, transfer on
                            death deed, or intestacy. An heir property owner not specifically identified as
                            the residence homestead owner on a deed or other recorded instrument in the
                            county where the property is located must provide:</p>
                        <ul>
                            <li>an affidavit establishing ownership of interest in the property (See Form 114-A);</li>
                            <li>a copy of the prior property owner's death certificate;</li>
                            <li>a copy of the property's most recent utility bill; and</li>
                            <li>A citation of any court record relating to the applicant's ownership of the property, if
                                available.</li>
                        </ul>

                        <p>Each heir property owner who occupies the property as a principal residence, other than the
                            applicant, must provide an affidavit that authorizes the submission of this application (See
                            Form
                            50-114-A).</p>

                        <p>Manufactured homeowners must provide:</p>

                        <ul>
                            <li>a copy of the Texas Department of Housing and Community Affairs statement of ownership
                                showing
                                that the applicant is the owner of the manufactured home;</li>
                            <li>a copy of the sales purchase agreement, other applicable contract or agreement or payment
                                receipt showing that the applicant is the purchaser of the manufactured home; or</li>
                            <li>a sworn affidavit (see Form 50-114-A) by the applicant indicating that:
                                <ol>
                                    <li>the applicant is the owner of the manufactured home;</li>
                                    <li>the seller of the manufactured home did not provide the applicant with the
                                        applicable
                                        contract
                                        or agreement; and</li>
                                    <li>the applicant could not locate the seller after making a good faith effort.</li>
                                </ol>

                            </li>
                        </ul>
                    </div>
                </div>

                <div class="public-info-item">
                    <div class="public-info-item-header">ADDITIONAL INFORMATION REQUEST</div>
                    <div class="public-info-content">
                        <p>The Chief Appraiser may request additional information to evaluate this
                            application. Property owner must comply within 30 days of the request or the
                            application will be denied. The Chief Appraiser may extend this deadline for a
                            single period not to exceed 15 days for good cause shown. (Tax Code Section
                            11.45)</p>
                    </div>

                </div>

                <div class="public-info-item">
                    <div class="public-info-item-header">Surviving Spouse of a First Responder Killed in the Line of Duty
                    </div>
                    <div class="public-info-content">
                        <p>(Tax Code Section 11.134) Surviving spouse of a first responder who is killed or fatally injured
                            in the line of duty who has not remarried since the death of the first responder.
                            Documentation must be provided to support this exemption request.</p>
                    </div>

                </div>
            </div>
            <!-- End first column-->

            <!-- Start second column -->
            <div class="public-info-column">

                <div class="public-info-item">
                    <div class="public-info-item-header">
                        DUTY TO NOTIFY
                    </div>
                    <div class="public-info-content">
                        <p>Property owner must notify the Chief
                            Appraiser in writing before May 1 of
                            the year after his or her right to this exemption ends.</p>
                    </div>

                </div>

                <div class="public-info-item">
                    <div class="public-info-item-header">
                        EXEMPTION QUALIFICATIONS
                    </div>
                    <div class="public-info-item-header">
                        General Residence Homestead Exemption
                    </div>
                    <div class="public-info-content">
                        <p>(Tax Code Section 11.13(a) and (b))
                            A property owner who acquires property
                            after Jan. 1 may receive the residence
                            homestead exemption for the applicable
                            portion of that tax year immediately on
                            qualification of the exemption, if the
                            previous owner did not receive the same
                            exemption for the tax year.
                            The property owner must occupy the
                            property as the owner's primary residence
                            and the residence homestead exemption
                            cannot be claimed by the property owner
                            on any other property.</p>
                    </div>

                </div>

                <div class="public-info-item">
                    <div class="public-info-item-header">
                        Disabled Person Exemption
                    </div>
                    <div class="public-info-content">
                        <p>(Tax Code Section 11.13(c) and (d))
                            Persons under a disability for purposes of
                            payment of disability insurance benefits
                            under Federal Old-Age, Survivors, and
                            Disability Insurance. Property owners not
                            identified on a deed or other instrument
                            recorded in the applicable real property
                            records as an owner of the residence
                            homestead must provide an affidavit or
                            other compelling evidence establishing
                            the applicant's ownership interest in the
                            homestead. (See Form 50-114-A)
                            An eligible disabled person age 65 or older
                            may receive both exemptions in the same
                            year, but not from the same taxing units.
                            Contact the appraisal district for
                            more information.</p>
                    </div>

                </div>

                <div class="public-info-item">
                    <div class="public-info-item-header">
                        Age 65 or Older Exemption
                    </div>
                    <div class="public-info-content">
                        <p>Tax Code Section 11.13(c) and (d))
                            This exemption is effective Jan. 1 of the
                            tax year in which the property owner
                            becomes age 65. Property owners not
                            identified on a deed or other instrument
                            recorded in the applicable real property
                            records as an owner of the residence
                            homestead must provide an affidavit or
                            other compelling evidence establishing
                            the applicant's ownership interest in the
                            homestead. (See Form 50-114-A)
                            An eligible disabled person age 65 or older
                            may receive both exemptions in the same
                            year, but not from the same taxing units.
                            Contact the appraisal district for
                            more information.</p>
                    </div>

                </div>

                <div class="public-info-item">
                    <div class="public-info-item-header">
                        Surviving Spouse of an Individual
                        Who Qualified for Age 65 or Older
                        Exemption
                    </div>
                    <div class="public-info-content">
                        <p>(Tax Code Section 11.13(q)):
                            Surviving spouse of person who qualified
                            for the age 65 or older exemption may
                            receive this exemption if the surviving
                            spouse was 55 years of age or older when
                            the qualifying spouse died.
                            The property must have been the surviving
                            spouse's residence homestead at the
                            time of death and remain the surviving
                            spouse's residence homestead.
                            This exemption cannot be combined
                            with an exemption under 11.13(d).</p>
                    </div>

                </div>

                <div class="public-info-item">
                    <div class="public-info-item-header">
                        100 Percent Disabled Veterans
                        Exemption
                    </div>
                    <div class="public-info-content">
                        <p>(Tax Code Section 11.131(b))
                            Property owner who has been awarded a
                            100 percent disability compensation due
                            to a service-connected disability and a
                            rating of 100 percent disabled or individual
                            unemployability from the U.S. Department
                            of Veterans Affairs or its successor.
                            Documentation must be provided to
                            support this exemption request.</p>
                    </div>

                </div>

                <div class="public-info-item">
                    <div class="public-info-item-header">
                        Surviving Spouse of a Disabled Veteran
                        Who Qualified or Would Have Qualified
                        for the 100 Percent Disabled Veteran's
                        Exemption
                    </div>
                    <div class="public-info-content">
                        <p>(Tax Code Section 11.131(c)
                            and (d))
                            Surviving spouse of a disabled veteran
                            (who qualified for an exemption under
                            Tax Code Section 11.131(b) at the time
                            of his or her death or would have
                            qualified for the exemption if the
                            exemption had been in effect on the
                            date the disabled veteran died) who has
                            not remarried since the death of the veteran.
                            The property must have been
                            the surviving spouse's residence
                            homestead at the time of the veteran's
                            death and remain the surviving
                            spouse's residence homestead.</p>
                    </div>

                </div>

                <div class="public-info-item">
                    <div class="public-info-item-header">
                        Donated Residence Homestead of
                        Partially Disabled Veteran
                    </div>
                    <div class="public-info-content">
                        <p>(Tax Code Section 11.132(b))
                            A disabled veteran with a disability rating
                            of less than 100 percent with a residence
                            homestead donated by a charitable
                            organization at no cost or at some cost
                            that is not more than 50 percent of the
                            good faith estimate of the market value
                            of the residence homestead as of the date
                            the donation is made. Documentation must
                            be provided to support this exemption
                            request.</p>
                    </div>

                </div>

                <div class="public-info-item">
                    <div class="public-info-item-header">
                        Surviving Spouse of a Disabled Veteran
                        Who Qualified for the Donated
                        Residence Homestead Exemption
                    </div>
                    <div class="public-info-content">
                        <p>Tax Code Section 11.132(c) and (d)):
                            Surviving spouse of a disabled veteran
                            (who qualified for an exemption under Tax
                            Code Section 11.132(b) at the time of his
                            or her death) who has not remarried since
                            the death of the disabled veteran and
                            maintains the property as his or her
                            residence homestead.</p>
                    </div>

                </div>

                <div class="public-info-item">
                    <div class="public-info-item-header">
                        Surviving Spouse of a Member of Armed
                        Services Killed in Line of Duty
                    </div>
                    <div class="public-info-content">
                        <p>Tax Code Section 11.133(b) and (c))
                            Surviving spouse of a U.S. armed services
                            member who is killed or fatally injured
                            in the line of duty who has not remarried
                            since the death of the service member.
                            Documentation must be provided to
                            support this exemption request.</p>
                    </div>

                </div>

            </div>
            <!-- End second column-->
        </div>
        <!-- end container -->
    </section>
</template>
