<template>
    <div>
        <div class="fa-custom-stack-container mt-2 me-3" v-tooltip.bottom="title" :class="{
            'fa-bg-pass': status === true,
            'fa-bg-fail': status === false,
            'fa-bg-grey': status === null
        }">
            <span class="status-check-icon fa" :class="iconClass"></span>
            <span class="status-check-icon fa" :class="getStatusIcon"></span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    iconClass: {
        type: String,
        required: true
    },
    status: Boolean
})

const getStatusIcon = computed(() => {
    if (props.status === true) {
        return 'fa-check-circle'
    }
    else if (props.status === false) {
        return 'fa-circle-xmark'
    }
    else {
        return ''
    }
})
</script>