<template>
    <div class="element-container">
        <label for="reason" class="form-label form-label-required">Reason</label>
        <Select :scrollHeight="PV_SCROLL_HEIGHT" id="reason" v-model="reasons" :options="reasonsDropdown" placeholder="-- Select --" class="d-flex" />
    </div>

    <div class="element-container">
        <label for="addNote" class="form-label" :class="{ 'form-label-required': reasons?.toLowerCase() === 'other' }">
            Add Note
        </label>
        <Textarea id="addNote" v-model="optionalNote" :maxlength="MAX_NOTE_LENGTH" class="d-flex" />
    </div>
</template>

<script setup lang="ts">
import { MAX_NOTE_LENGTH, PV_SCROLL_HEIGHT } from '@/helpers/common'
import Textarea from 'primevue/textarea'
import Select from 'primevue/select'

const reasons = defineModel<string | null>("reasons")
const optionalNote = defineModel<string | null>("optionalNote")
const { reasonsDropdown } = defineProps({
    reasonsDropdown: {
        required: true
    }
})
</script>