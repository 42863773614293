<template>
    <div id="footnotes" class="footnotes">
        <p><sup>1</sup> May be used by appraisal district to determine eligibility for persons age 65 or older exemption or
            surviving spouse exemptions (Tax Code §11.43(m)).
        </p>
        <p><sup>2</sup> Social security number disclosure may be required for tax administration and identification. (42
            U.S.C. §405(c)(2)(C)(i); Tax Code §11.43(f)). A driver license number, personal identification number or
            social security number disclosed in an exemption application is confidential and not open to public inspection,
            except as authorized by Tax Code §11.48(b).
        </p>
        <p><sup>3</sup> May be confidential under Government Code §552.137; however, by including the email address on this
        form, you are affirmatively consenting to its release under the Public Information Act.
        </p>
    </div>
</template>
