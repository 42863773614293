<template>
    <div v-if="isLoading" class="p-4">
        <LoadingIcon />
    </div>
    
    <div v-else class="mx-auto" style="max-width: 1100px;">
        <iframe v-if="dashboardUrl" :src="dashboardUrl" height="1200" width="1100"></iframe>
        
        <div v-else style="padding: 1em;">
            <Message severity="info" :closable="false">
                Please <a href="mailto:support@trueroll.io" style="text-decoration: underline;">contact TrueRoll support</a> to enable this feature. 
            </Message>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useAPI } from "@/helpers/services/api"
import { useAuth0 } from '@auth0/auth0-vue'
import { toast } from "@/helpers/toast"
import { getApiErrorMessage } from "@/helpers/common"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue"
import Message from "primevue/message"
import { ClaimsFields } from '@/helpers/roles'

const props = defineProps({
    setting_name: {
        type: String,
        required: true
    }
})

const api = useAPI()
const { user } = useAuth0();

const dashboardUrl = ref("")
const isLoading = ref(true)

const getCustomerId = () => {
    const output = ~~(user.value?.[ClaimsFields["app_metadata"]]?.customer_id)
    return output
}

const fetchDashboardUrlSetting = () => {
    isLoading.value = true

    api.get(`/settings/internal/${props.setting_name}`)?.then(response => {
        dashboardUrl.value = response.data.value?.replace("{customer_id}", getCustomerId())
    })?.catch(error => {
        toast.error(getApiErrorMessage(error))
    })?.finally(() => {
        isLoading.value = false
    })
}


onMounted(() => {
    fetchDashboardUrlSetting()
})
</script>