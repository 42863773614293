<template>
    <div>
        <div id="taxroll-list-container"></div>
    </div>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import type { PropType } from 'vue';
import type {
    ColumnDefinition,
    CellComponent
} from "tabulator-tables";
import {
    blankIf,
    ucfirst,
    buildCopyToClipboardButton
} from '@/helpers/common';
import { ref, onMounted, watch, onUnmounted } from 'vue';
import type { TabulatorFull as Tabulator } from 'tabulator-tables';
import { createAjaxTabulator, destroyTabulator } from "@/helpers/true-tabulator";
import type {
    CandidateListRequestHeader,
    TaxRollList,
    CandidateFilter
} from "@/helpers/interface/candidates";

interface Detail {
    text: string
    value: keyof TaxRollList
}

let tabulator: Tabulator | null;
const baseURL = import.meta.env.VITE_DEV_API_URL;
const props = defineProps({
    requestHeaders: {
        type: Object as PropType<CandidateListRequestHeader>,
        required: true
    }
});
const requestHeaderFilter: any = ref(props.requestHeaders.filter);

const candidatesListColumns: ColumnDefinition[] = [
    {
        title: "Parcel Number",
        field: "parcel_num",
        headerSort: false,
        visible: true,
        width: "25%",
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as TaxRollList;
            const tru_id = data.tru_id
            const parcel_num = data.parcel_num

            const parent = document.createElement("div")
            parent.className = "row list-row"
            parent.id = "details"

            function appendDetailRow(container: HTMLElement, label: string, value: string): void {
                const rowDiv = document.createElement("div")
                rowDiv.className = "col-3"
                rowDiv.textContent = `${label}: `
                container.appendChild(rowDiv)

                const valueDiv = document.createElement("div")
                valueDiv.className = "col-9 text-truncate white-space-normal"

                if (["PIN", "County Lookup"].includes(label) && value) {
                    // Append link
                    const link = document.createElement("a")
                    link.target = "_blank"
                    link.className = "candidate-list-link"
                    if (label === "County Lookup") {
                        link.href = value
                        link.innerText = "Link"
                    }
                    else if (label === "PIN") {
                        link.href = `/taxroll-details/${tru_id}`
                        link.innerText = parcel_num
                    }
                    valueDiv.append(link)
                }
                else {
                    // Text only
                    valueDiv.innerHTML = value
                }

                // Append copy button
                if (["Situs", "PIN"].includes(label) && value) {
                    valueDiv.className = "col-9 text-truncate d-flex"
                    const copyElement = document.createElement("span")
                    copyElement.classList.add("clipboard-copy-target")
                    copyElement.setAttribute("id", `${label}-${tru_id}`)
                    copyElement.setAttribute("data-copy-text", value)
                    valueDiv.append(copyElement)
                }

                container.appendChild(valueDiv)
            }

            ([
                { text: "PIN", value: "parcel_num" },
                { text: "Situs", value: "situs" },
                { text: "County Lookup", value: "county_lookup_url" },
            ] as Detail[]).forEach((detail) => {
                appendDetailRow(parent, detail.text, (data[detail.value]) as string)
            });

            return parent
        }
    },
    {
        title: "Details", field: "owner", hozAlign: 'left',
        headerSort: false,
        width: "30%",
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as TaxRollList;
            const tru_id = data.tru_id

            const parent = document.createElement("div")
            parent.className = "row list-row"
            parent.id = "details"

            function appendDetailRow(container: HTMLElement, label: string, value: string): void {
                const rowDiv = document.createElement("div")
                rowDiv.className = "col-3"
                rowDiv.textContent = `${label}: `
                container.appendChild(rowDiv)

                const valueDiv = document.createElement("div")
                valueDiv.className = "col-9 text-truncate white-space-normal"
                valueDiv.innerHTML = value

                // Append copy button
                if (["Mailing"].includes(label) && value) {
                    valueDiv.className = "col-9 text-truncate d-flex"
                    const copyElement = document.createElement("span")
                    copyElement.classList.add("clipboard-copy-target")
                    copyElement.setAttribute("id", `${label}-${tru_id}`)
                    copyElement.setAttribute("data-copy-text", value)
                    valueDiv.append(copyElement)
                }

                container.appendChild(valueDiv)
            }

            ([
                { text: "Owner", value: "owner" },
                { text: "Mailing", value: "mail" },
                { text: "Exemption(s)", value: "exemptions" },
            ] as Detail[]).forEach((detail) => {
                appendDetailRow(parent, detail.text, (data[detail.value]) as string)
            });

            return parent
        }
    },
    {
        title: "Values", field: "flags", hozAlign: 'left',
        headerSort: false,
        width: "20%",
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as TaxRollList;
            const market_value = data.market_value
            const assessed_value = data.assessed_value
            const taxable_value = data.taxable_value
            return `
                <div class="row list-row">
                    <div class="col-5">Market Value:</div> <div class="col-7">${market_value}</div>
                    <div class="col-5">Assessed Value:</div> <div class="col-7">${assessed_value}</div>
                    <div class="col-5">Taxable Value:</div> <div class="col-7">${taxable_value}</div>
                </div>
            `;
        }
    },
    {
        title: "Candidate Details", field: "queue", hozAlign: 'left',
        headerSort: false,
        width: "25%",
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as TaxRollList;
            const candidate_queue = blankIf(ucfirst(data.queue))
            const identified_on = data.identified_on
            const updated_on = data.updated_on
            const parcel_created = data.parcel_created
            const parcel_updated = data.parcel_updated

            let _identified_on = blankIf(identified_on) == "" ? "" : dayjs(identified_on).format("MM/DD/YYYY")
            let _updated_on = blankIf(updated_on) == "" ? "" : dayjs(updated_on).format("MM/DD/YYYY")
            let _parcel_created = blankIf(parcel_created) == "" ? "" : dayjs(parcel_created).format("MM/DD/YYYY")
            let _parcel_updated = blankIf(parcel_updated) == "" ? "" : dayjs(parcel_updated).format("MM/DD/YYYY")
            return `
                <div class="row list-row">
                    <div class= "col-5"> Candidate Queue: </div> <div class="col-7">${candidate_queue}</div>
                    <div class= "col-5"> Candidate found: </div> <div class="col-7">${_identified_on}</div>
                    <div class= "col-5"> Candidate updated: </div> <div class="col-7">${_updated_on}</div>
                    <div class= "col-5"> Parcel Created: </div> <div class="col-7">${_parcel_created}</div>
                    <div class= "col-5"> Parcel Updated: </div> <div class="col-7">${_parcel_updated}</div>
                </div>
            `;
        }
    }
]

async function candidates(filter: CandidateFilter[]) {

    if (tabulator) {
        tabulator.clearAlert()
        tabulator.setFilter(filter)
        return
    }

    tabulator = await createAjaxTabulator("#taxroll-list-container", {
        columns: candidatesListColumns,
        height: "700px",
        placeholder: "There are no parcels to display",
        pagination: true,
    })

    if (!tabulator) return
    tabulator.on("tableBuilt", () => {
        if (!tabulator)
            return
        tabulator.setFilter(filter)
        tabulator.setData(`${baseURL}/taxroll/`)
        setTimeout(() => buildCopyToClipboardButton(), 1000)
    })
    tabulator.on("pageLoaded", () => setTimeout(() => buildCopyToClipboardButton(), 1000))
    tabulator.on("scrollVertical", () => buildCopyToClipboardButton())
}

onMounted(async () => {
    await candidates(requestHeaderFilter.value)
})

onUnmounted(() => {
    destroyTabulator(tabulator)
})

watch(() => props.requestHeaders.filter, (first) => {
    requestHeaderFilter.value = first
    candidates(requestHeaderFilter.value)
});
</script>

<style>
@import "@/assets/candidates-list.css";
@import "tabulator-tables";
</style>
