<template>
    <sup>
        <a :href="props.target">{{ props.text }}</a>
    </sup>
</template>

<script setup lang="ts">

const props = defineProps({
    text: {
        type: String,
        required: true
    },
    target: {
        type: String, 
        required: true
    }
})
</script>
