import { PanZoomer } from "@/helpers/image-tools";

let pz: PanZoomer | null = null;

export const onGalleriaImageRender = (e: Event) => {
    pz = new PanZoomer(".p-galleria-item img")
}

export const handleGalleriaImageZoomIn = (e: Event) => {
    if (pz) {
        pz.zoomIn()
    }
}

export const handleGalleriaImageZoomOut = (e: Event) => {
    if (pz) {
        pz.zoomOut()
    }
}

export const handleGalleriaImageRotate = (value: number) => {
    if (pz) {
        pz.rotate(value)
    }
}
