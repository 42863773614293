<template>
	<div class="card-cl-actions" style="height: 500px; width: 100%">
		<div class="card-body">
			<ul class="nav nav-pills" id="myTab" role="tablist">
				<li class="nav-item" role="presentation">
					<a class="nav-link active" id="update-tab" data-bs-toggle="tab" data-bs-target="#update-tab-pane"
						type="button" role="tab" aria-controls="update-tab-pane" aria-selected="true">Update</a>
				</li>
				<li class="nav-item" role="presentation">
					<a class="nav-link" id="flags-tab" data-bs-toggle="tab" data-bs-target="#flags-tab-pane" type="button"
						role="tab" aria-controls="flags-tab-pane" aria-selected="true">+Flags</a>
				</li>
				<li class="nav-item" role="presentation">
					<a class="nav-link" id="labels-tab" data-bs-toggle="tab" data-bs-target="#labels-tab-pane" type="button"
						role="tab" aria-controls="labels-tab-pane" aria-selected="true">+Labels</a>
				</li>
				<li class="nav-item" role="presentation">
					<a class="nav-link" id="docs-tab" data-bs-toggle="tab" data-bs-target="#docs-tab-pane" type="button"
						role="tab" aria-controls="docs-tab-pane" aria-selected="true">+Docs</a>
				</li>
				<li class="nav-item" role="presentation">
					<a class="nav-link" id="mail-tab" data-bs-toggle="tab" data-bs-target="#mail-tab-pane" type="button"
						role="tab" aria-controls="mail-tab-pane" aria-selected="true">+Mail</a>
				</li>
				<li class="nav-item" role="presentation" v-if="hasPermissionToCreateIR">
					<a class="nav-link" id="report-tab" data-bs-toggle="tab" data-bs-target="#report-tab-pane" type="button"
						role="tab" aria-controls="report-tab-pane" aria-selected="true">+Report</a>
				</li>
			</ul>
			<div class="tab-content accordion" id="myTabContent">
				<div class="tab-pane fade show active accordion-item" id="update-tab-pane" role="tabpanel"
					aria-labelledby="update-tab" tabindex="0">
					<div id="collapseOne" class="accordion-collapse collapse show  d-lg-block" aria-labelledby="headingOne"
						data-bs-parent="#myTabContent">
						<div class="accordion-body m-1">
							<CandidateUpdateForm />
						</div>
					</div>
				</div>
				<div class="tab-pane fade accordion-item" id="flags-tab-pane" role="tabpanel" aria-labelledby="flags-tab"
					tabindex="0">
					<div id="collapseTwo" class="accordion-collapse collapse show d-lg-block" aria-labelledby="headingTwo"
						data-bs-parent="#myTabContent">
						<div class="accordion-body m-1">
							<CandidateFlagForm />
						</div>
					</div>
				</div>
				<div class="tab-pane fade accordion-item" id="labels-tab-pane" role="tabpanel" aria-labelledby="labels-tab"
					tabindex="0">
					<div id="collapseThree" class="accordion-collapse collapse show d-lg-block"
						aria-labelledby="headingThree" data-bs-parent="#myTabContent">
						<div class="accordion-body m-1">
							<CandidateLabelsForm />
						</div>
					</div>
				</div>

				<div class="tab-pane fade accordion-item" id="docs-tab-pane" role="tabpanel" aria-labelledby="docs-tab"
					tabindex="0">
					<div id="collapseFour" class="accordion-collapse collapse show d-lg-block" aria-labelledby="headingFour"
						data-bs-parent="#myTabContent">
						<div class="accordion-body m-1">
							<CandidateDocumentForm />
						</div>
					</div>
				</div>

				<div class="tab-pane fade accordion-item" id="mail-tab-pane" role="tabpanel" aria-labelledby="mail-tab"
					tabindex="0">
					<div id="collapseFive" class="accordion-collapse collapse show d-lg-block" aria-labelledby="headingFive"
						data-bs-parent="#myTabContent">
						<div class="accordion-body m-1">
							<CandidateMailForm />
						</div>
					</div>
				</div>

				<div class="tab-pane fade accordion-item" id="report-tab-pane" role="tabpanel" aria-labelledby="report-tab"
					tabindex="0" v-if="hasPermissionToCreateIR">
					<div id="collapseSix" class="accordion-collapse collapse show d-lg-block" aria-labelledby="headingSix"
						data-bs-parent="#myTabContent">
						<div class="accordion-body m-1">
							<CandidateInvestigativeReport />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import CandidateUpdateForm from "@/components/Shared/FormAction/CandidateUpdateForm.vue"
import CandidateFlagForm from "@/components/Shared/FormAction/CandidateFlagForm.vue"
import CandidateLabelsForm from "@/components/Shared/FormAction/CandidateLabelsForm.vue"
import CandidateDocumentForm from "@/components/Shared/FormAction/CandidateDocumentForm.vue"
import CandidateMailForm from "@/components/Shared/FormAction/CandidateMailForm.vue"
import CandidateInvestigativeReport from "@/components/Shared/FormAction/CandidateInvestigativeReport.vue"
import { computed } from "vue"
import { validateUserPermission } from "@/helpers/common"

const hasPermissionToCreateIR = computed(() => validateUserPermission("create", "released_investigation_reports"))
</script>

<style>
@import "@/assets/candidate-details.css";

.actions-description {
	background: #004eff1f;
	padding: 5px;
	font-size: 13px;
	margin-bottom: 5px;
}

.accordion-body {
	padding: 5px !important;
}

textarea {
	width: 100%;
	height: 100px;
}

.textwrapper {
	border: 1px solid var(--main-input-border-color);
	margin: 5px 0;
	padding: 3px;
}
</style>
