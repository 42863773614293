<template>
    <div>
        <TheHeader />
        <div class="">
            <div class="row g-2" style="background-color: #F9F9F9;">
                <div class="col-sm-3 col-xs-3 col-md-3 col-lg-2 sub-menu">
                    <div class="box p-1">
                        <div>
                            <AdminSubMenu @changeSubPage="updateSubPage" :selectedSubPage="currentSubPage"
                                :menu="SubMenuSettingsConfig_Filtered" :header="'Settings'"
                                v-if="SubMenuSettingsConfig_Filtered?.length !== 0" />
                            <AdminSubMenu @changeSubPage="updateSubPage" :selectedSubPage="currentSubPage"
                                :menu="SubMenuAdminConfig_Filtered" :header="'Admin'"
                                v-if="SubMenuAdminConfig_Filtered?.length !== 0" />
                        </div>
                        <hr>
                        <div class="container text-center pb-2" id="user-info-container">
                            {{ ucfirst(user.name) }} @ {{ currentCustomerAccess }}
                        </div>
                    </div>
                </div>
                <div class="col-sm-9 col-xs-3 col-md-9 col-lg-10 sub-menu">
                    <div class="box p-1">
                        <AdminSubPage :page="currentSubPage" />
                    </div>
                </div>
            </div>
        </div>
        <TheFooter />
    </div>
    <AppMonitor />
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue"
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import AppMonitor from "@/components/AppMonitor.vue"
import AdminSubMenu from "@/components/Admin/AdminSubMenu.vue"
import AdminSubPage from "@/components/Admin/AdminSubPage.vue"
import { useAuth0 } from '@auth0/auth0-vue';
import { useRoute } from 'vue-router';
import { useProfile } from "@/stores/profile";
import { ucfirst } from "@/helpers/common"
import { AdminConfig, SettingsConfig } from "@/helpers/adminSubMenuConfig"

const { user } = useAuth0();
const storeProfile = useProfile();
const route = useRoute()
const currentSubPage = ref<string>("update_archived_candidates")
const routeSelected = route?.query.sub_page as string
const updateSubPage = (page: string) => currentSubPage.value = page
const currentCustomerAccess = computed(() => (storeProfile.getCustomerAccess?.name))
const currentPermissions = computed(() => (storeProfile.getCurrentPermissions))

const SubMenuAdminConfig_Filtered = computed(() => {
    return AdminConfig.filter((menu) => {
        const requiredPermissions = menu.requiredPermissions
        if (requiredPermissions.length === 0) return true

        return requiredPermissions.some(permission => {
            return currentPermissions.value.includes(permission)
        })
    })
})

const SubMenuSettingsConfig_Filtered = computed(() => {
    return SettingsConfig.filter((menu) => {
        const requiredPermissions = menu.requiredPermissions
        if (requiredPermissions.length === 0) return true

        return requiredPermissions.some(permission => {
            return currentPermissions.value.includes(permission)
        })
    })
})

onMounted(async () => {
    await storeProfile.fetchUserPermissions()
    if (routeSelected === null || routeSelected === undefined) return
    currentSubPage.value = routeSelected
})
</script>

<style>
@import "@/assets/admin-page.css";
</style>