<template>
	<div class="print-container">
		<div id="docket-report">
			<div class="row">
				<div class="col">
					<div id="situs-info">
						<h1><b>Candidate Docket Report</b></h1>
						<div class="actions-description">
							Right click and select print to generate a printed or pdf version of this docket using your
							browser's print function
						</div>
						<h3><b>Candidate Details</b></h3>
						<h2 class="info-header">SITUS INFO</h2>
						<table class="table table-striped" v-if="info.tru_id != ''">
							<tbody>
								<tr v-for="si in siteInfo" :key="si.key">
									<td class="key">{{ si.key }}</td>
									<td class="value">
										<div v-if="si.key.toLocaleLowerCase().includes('url')">
											<a :href="(si.value as string)" target="_blank">Link</a>
										</div>
										<div v-else-if="si.key.toLocaleLowerCase().includes('date')">
											{{ (si.value as string !== "") ? dayjs((si.value as
												string)).format("MM/DD/YYYY") : "" }}
										</div>
										<div v-else>
											{{ si.value }}
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<h2 class="info-header">MAILING INFO</h2>
						<table class="table table-striped" v-if="info.tru_id != ''">
							<tbody>
								<tr v-for="si in mailingInfo" :key="si.key">
									<td class="key">{{ si.key }}</td>
									<td class="value">{{ si.value }}</td>
								</tr>
							</tbody>
						</table>
						<h2 class="info-header">CANDIDATE STATUS</h2>
						<table class="table table-striped" v-if="info.tru_id != '' && info.queue">
							<tbody>
								<tr v-for="si in candidateStatus" :key="si.key">
									<td class="key">{{ si.key }}</td>
									<td class="value">{{ getDisplayValue(si.value) }}</td>
								</tr>
							</tbody>
						</table>
						<table class="table table-striped" v-if="info.tru_id != '' && !info.queue">
							<tbody>
								<tr>
									<td class="value">Not a Candidate</td>
								</tr>
							</tbody>
						</table>
					</div>
					<h3><b>Candidate Flags</b></h3>
					<div id="candidate-flags"></div>
					<br>
					<h3><b>Candidate Additional Details</b></h3>
					<div id="candidate-notes"></div>
					<br>
					<h3><b>Candidate History</b></h3>
					<div id="candidate-history"></div>
				</div>
			</div>
		</div>
	</div>
	<AppMonitor />
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import { useRoute } from "vue-router";
import type { AxiosError } from "axios"
import { toast } from "@/helpers/toast";
import { computed, onMounted, ref, watch, onUnmounted } from "vue";
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import { getApiErrorMessage, formatProperties, situsInfoPrettyNames, ucfirst, sanitizeHTML, updateTypes, filterStatus, getDisplayValue } from "@/helpers/common"
import type { FlagsAPIResponse, HistoryAPIResponse } from "@/helpers/interface/candidates"
import type { ColumnDefinition, CellComponent, } from "tabulator-tables";
import { TabulatorFull as Tabulator } from 'tabulator-tables'; //import Tabulator library
import { destroyTabulator } from "@/helpers/true-tabulator";
import AppMonitor from "@/components/AppMonitor.vue"
import { setPageTitle } from "@/helpers/common";

const route = useRoute()
const store = useCandidateDetailsStore();
const tru_id = ref(route?.params?.tru_id as string)

const mailingFields = [
	"mail_full_taxroll",
	"mail_county",
];
const statusFields = [
	"queue",
	"reason",
	"unqualified_start_year",
	"lien_or_back_taxes",
	"snooze_until",
	"final_outcome",
]
const info = computed(() => (store.situsInfo))
const siteInfo = computed(() => (formatProperties(info.value, situsInfoPrettyNames, [], [...mailingFields, ...statusFields, ...["assessed_value", "market_value"]])));
const mailingInfo = computed(() => (formatProperties(info.value, situsInfoPrettyNames, mailingFields)))
const candidateStatus = computed(() => {
	/* if we have a queue, then its a candidate and we display candidate fields
		otherwise, it's just a taxroll parcel, and there's no table */
	if (info.value.queue)
		return formatProperties(info.value, situsInfoPrettyNames, statusFields.filter(x => { return filterStatus(info.value, x) }))
	else
		return []
})
const flags = computed((): FlagsAPIResponse[] => (store.getFlags))
const notes = computed((): FlagsAPIResponse[] => (store.getNotes))
const history = computed((): HistoryAPIResponse[] => (store.getHistory))

let candidateFlagsTabulator: Tabulator | null
let candidateNotesTabulator: Tabulator | null
let candidateHistoryTabulator: Tabulator | null

const candidateFlagsTabulatorData = ref(flags);
const candidateNotesTabulatorData = ref(notes);
const candidateHistoryTabulatorData = ref(history);

const candidateNotesTabulatorColumn: ColumnDefinition[] = [
	{
		title: "Type",
		field: "name",
		headerSort: true,
		width: "20%"
	},
	{
		title: "Additional Details",
		field: "display_html",
		width: "50%",
		headerSort: true,
		formatter: function (cell: CellComponent) {
			const data = cell.getData() as FlagsAPIResponse;
			const display_html = data.display_html || ""
			return display_html.trim()
		}
	},
	{
		title: "Date",
		field: "event_date",
		width: "30%",
		headerSort: true,
		formatter: function (cell: CellComponent) {
			return dayjs(cell.getValue()).format("M/D/YYYY")
		}
	}
]


const candidateFlagsTabulatorColumn = ref<ColumnDefinition[]>([
	{
		title: "Flags",
		field: "name",
		headerSort: false,
		width: "20%"
	},
	{
		title: "Details",
		field: "display_html",
		width: "50%",
		headerSort: false,
		formatter: function (cell: CellComponent) {
			const data = cell.getData() as FlagsAPIResponse;
			const display_html = data.display_html || ""
			return display_html.trim()
		}
	},
	{
		title: "Date",
		field: "event_date",
		width: "15%",
		headerSort: false,
		formatter: function (cell: CellComponent) {
			return dayjs(cell.getValue()).format("M/D/YYYY")
		}
	}
])

const candidateHistoryTabulatorColumn: ColumnDefinition[] = [
	{
		title: "Update Type",
		field: "queue",
		headerSort: false,
		width: "20%",
		formatter: function (cell: CellComponent) {
			const data = cell.getData() as HistoryAPIResponse
			return updateTypes[data.update_type_id]
		}
	},
	{
		title: "Details",
		width: "40%",
		headerSort: false,
		field: "queue",
		formatter: function (cell: CellComponent) {
			const data = cell.getData() as HistoryAPIResponse
			return sanitizeHTML(data.update_details || "")
		}
	},
	{
		title: "User",
		width: "20%",
		headerSort: false,
		field: "created_by",
		formatter: function (cell: CellComponent) {
			const data = cell.getData() as HistoryAPIResponse
			return ucfirst(data.created_by)
		}
	},
	{
		title: "Date",
		width: "20%",
		headerSort: false,
		field: "created_at",
		formatter: function (cell: CellComponent) {
			const data = cell.getData() as HistoryAPIResponse
			const created_at = data.created_at
			return dayjs((created_at)).format("M/D/YYYY")
		}
	}
]
const loadFailureHandler = (error: AxiosError) => {
	const message = getApiErrorMessage(error, { "featureName": "Candidate" })
	toast.error(message)
}

onMounted(async () => {
	await store.fetchSitusInfo(tru_id.value, loadFailureHandler)
	await store.fetchFlags(tru_id.value, loadFailureHandler)
	await store.fetchNotes(tru_id.value, loadFailureHandler)
	await store.fetchHistory(tru_id.value, loadFailureHandler)

	// Hide get help button
	window.addEventListener('productfruits_ready', function () {
		const getHelpButton = document.querySelector(".productfruits--container") as HTMLIFrameElement
		if (getHelpButton) {
			getHelpButton.style.display = "none"
		}
	})

	setPageTitle(`Candidate Docket Report: ${tru_id.value}`)
})

watch(() => candidateFlagsTabulatorData.value, (data) => {
	if (!candidateFlagsTabulator) {
		candidateFlagsTabulator = new Tabulator("#candidate-flags", {
			data: data,
			columns: candidateFlagsTabulatorColumn.value,
			placeholder: "No records found.",
			autoColumns: false,
			initialSort: [
				{ column: "event_date", dir: "desc" },
			]
		});
	} else {
		candidateFlagsTabulator.setData(data)
	}
})

watch(() => candidateNotesTabulatorData.value, (data) => {
	if (!candidateNotesTabulator) {
		candidateNotesTabulator = new Tabulator("#candidate-notes", {
			data: data,
			columns: candidateNotesTabulatorColumn,
			placeholder: "No records found.",
			autoColumns: false,
		});
	} else {
		candidateNotesTabulator.setData(data)
	}
})

watch(() => candidateHistoryTabulatorData.value, (data) => {
	if (!candidateHistoryTabulator) {
		candidateHistoryTabulator = new Tabulator("#candidate-history", {
			data: data,
			layout: "fitDataFill",
			columns: candidateHistoryTabulatorColumn,
			placeholder: "No records found.",
			autoColumns: false,
			maxHeight: "100%",
			initialSort: [
				{ column: "created_at", dir: "desc" },
			]
		});
	} else {
		candidateHistoryTabulator.setData(data)
	}
})
onUnmounted(() => {
	destroyTabulator(candidateFlagsTabulator)
})
</script>

<style>
@import "@/assets/docket-report.css";
</style>