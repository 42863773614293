<template>
    <div class="element-container">
        <label for="finalOutcome" class="form-label form-label-required">Final Outcome</label>
        <Select :scrollHeight="PV_SCROLL_HEIGHT" id="finalOutcome" v-model="finalOutcome" :options="finalOutcomeOptions" placeholder="-- Select --"
            optionLabel="text" optionValue="value" class="d-flex" />
    </div>

    <div v-if="finalOutcome === OUTCOME_EXEMPTION_REMOVED">
        <div class="element-container">
            <label for="unqualifiedStartYear" class="form-label form-label-required">Unqualified Start Year</label>
            <Select :scrollHeight="PV_SCROLL_HEIGHT" id="unqualifiedStartYear" v-model="unqualifiedStartYear" :options="unqualifiedDropdown"
                placeholder="-- Select --" class="d-flex" />
        </div>

        <div class="element-container">
            <label for="lienOrBackTaxes" class="form-label form-label-required">Lien or Back Taxes</label>
            <Select :scrollHeight="PV_SCROLL_HEIGHT" id="lienOrBackTaxes" v-model="lienOrBackTaxes" :options="lienOrBackTaxesOptions"
                placeholder="-- Select --" class="d-flex" />
        </div>

        <div class="element-container" v-if="totalCandidateSelected === 1">
            <label for="recoveredAmount" class="form-label form-label-required">Estimated Recovered Amount</label>
            <InputNumber v-model="recoveredAmount" inputId="recoveredAmount" fluid class="d-flex w-100" />
        </div>

        <div v-if="lienOrBackTaxes === 'Yes'">
            <div class="element-container">
                <label for="backTaxLienAmount" class="form-label">Back Tax / Lien Amount</label>
                <InputNumber v-model="backTaxLienAmount" inputId="backTaxLienAmount" fluid class="d-flex my-2 w-100" />
            </div>
        </div>
    </div>

    <div class="element-container">
        <label for="addNote" class="form-label">
            Add Note
        </label>
        <Textarea id="addNote" v-model="optionalNote" :maxlength="MAX_NOTE_LENGTH" class="d-flex" />
    </div>
</template>

<script setup lang="ts">
import { MAX_NOTE_LENGTH, PV_SCROLL_HEIGHT } from '@/helpers/common'
import Textarea from 'primevue/textarea'
import Select from 'primevue/select'
import InputNumber from 'primevue/inputnumber'
import { ref } from 'vue'
import dayjs from 'dayjs'

const finalOutcome = defineModel<string | null>("finalOutcome")
const recoveredAmount = defineModel<number | null>("recoveredAmount")
const unqualifiedStartYear = defineModel<string | null>("unqualifiedStartYear")
const lienOrBackTaxes = defineModel<string | null>("lienOrBackTaxes")
const backTaxLienAmount = defineModel<number | null>("backTaxLienAmount")
const optionalNote = defineModel<string | null>("optionalNote")

const { startYear, totalCandidateSelected } = defineProps({
    startYear: {
        default: 2009,
        type: Number
    },
    totalCandidateSelected: {
        type: Number
    }
})

const finalOutcomeOptions = [
    { value: "Property Sold", text: "Property Sold" },
    { value: "Exemption Removed", text: "Exemption Removed" },
]

const unqualifiedDropdown = ref<string[]>([])
const currentYear: number = dayjs().year()
unqualifiedDropdown.value = []
for (let index = (currentYear + 1); index >= startYear; index--) {
    unqualifiedDropdown.value.push(index.toString());
}

const lienOrBackTaxesOptions = ["Yes", "No"]
const OUTCOME_EXEMPTION_REMOVED = "Exemption Removed"
</script>