<template>
    <div>
        <label class="config-header">Update: Archived Candidates</label>
        <div class="actions-description">
            TrueRoll's Candidate screens do not allow archived candidates to be updated - this is because archiving has
            implications beyond the other queues. In the case you need to update an archived Candidate, this screen will be
            of assistance.
        </div>
        <form @submit.prevent="unarchivedCandidate()" class="my-3">
            <div class="row">
                <div class="col-sm-4">
                    <label for="unarchive-parcel-id">Parcel Number</label>
                    <br />
                    <input type="text" class="cbFormTextField cbFormTextField-sm" id="unarchive-parcel-id"
                        placeholder="Archived parcel number" v-model="formUnarchiveCandidate.parcel_id" required>
                    <div class="d-flex">
                        <button class="searchButton cbFormTextField-sm mt-2" type="submit" :disabled="formLoading">
                            Unarchive Candidate
                        </button>
                        <loading-icon v-if="formLoading" />
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { toast } from "@/helpers/toast";
import { useAPI } from "@/helpers/services/api";
import LoadingIcon from "@/components/Shared/LoadingIcon.vue"
import { getApiErrorMessage } from "@/helpers/common"

const api = useAPI()

const formLoading = ref(false)
const formUnarchiveCandidate = ref<{ parcel_id: string }>({
    parcel_id: "",
})


let unarchivedCandidate = async () => {
    const { parcel_id } = formUnarchiveCandidate.value
    formLoading.value = true

    // Submit Request
    try {
        await api.patch(`/taxroll/unarchive/${encodeURIComponent(parcel_id)}`);
        toast.success(`Parcel ${parcel_id} has been unarchived`)
        formUnarchiveCandidate.value.parcel_id = ""
    }
    catch (error: any) {
        toast.error(getApiErrorMessage(error))
    }
    finally {
        formLoading.value = false;
    }
}
</script>
