<template>
    <div>
        <div class="row">
            <div class="col-lg-6">
                <div v-if="!fetchInternalSettingsLoading">

                    <h3 class="config-header">General</h3>

                    <form class="my-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row pt-2">
                                    <div class="col-12">
                                        <label for="betaFeature">Beta Features (Customer Level)</label>
                                        <br>
                                        <RadioButton v-model="enableBetaFeatures" class="mx-1 mt-2"
                                            inputId="betaFeatureEnable" :value="true" @change="updateBetaFeatures" />
                                        <label for="betaFeatureEnable" class="me-1">Enabled</label>

                                        <RadioButton v-model="enableBetaFeatures" class="mx-1 mt-2"
                                            inputId="betaFeatureDisable" :value="false" @change="updateBetaFeatures" />
                                        <label for="betaFeatureDisable">Disabled</label>

                                        <loading-icon class="ms-2 d-inline-flex"
                                            v-if="isSavingItem('enable_beta_features')" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <!-- ######################################################## -->
                    <!--  PROMON -->

                    <h3 class="config-header">Proactive Monitoring</h3>

                    <form class="my-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row pt-2">
                                    <div class="col-12">
                                        <label for="prioritizationModel">Prioritization Model</label>
                                        <br>
                                        <RadioButton v-model="prioritizationModel" class="mx-1 mt-2"
                                            inputId="prioritizationModelAdditive" value="additive"
                                            @change="updatePrioritizationModel" />
                                        <label for="prioritizationModelAdditive" class="me-1">Additive</label>

                                        <RadioButton v-model="prioritizationModel" class="mx-1 mt-2"
                                            inputId="prioritizationModelWeighted" value="weighted"
                                            @change="updatePrioritizationModel" />
                                        <label for="prioritizationModelWeighted">Weighted</label>

                                        <loading-icon class="ms-2 d-inline-flex"
                                            v-if="isSavingItem('prioritization_model')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <form class="my-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row pt-2">
                                    <div class="col-12">
                                        <label for="promonListLayout">List Layout</label>
                                        <br>
                                        <RadioButton v-model="promonListLayout" class="mx-1 mt-2"
                                            inputId="promonListLayoutPS" :value="'priority_score'"
                                            @change="updatePromonListLayout" />
                                        <label for="promonListLayoutPS" class="me-1">Priority Score</label>

                                        <RadioButton v-model="promonListLayout" class="mx-1 mt-2"
                                            inputId="promonListLayoutF" :value="'flags'"
                                            @change="updatePromonListLayout" />
                                        <label for="promonListLayoutF">Flags</label>

                                        <loading-icon class="ms-2 d-inline-flex"
                                            v-if="isSavingItem(InternalSettings.promon_list_layout)" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <form @submit.prevent="" class="my-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row pt-2">
                                    <div class="col-12">
                                        <label for="promonDefaultSnoozeDays">Default Snooze Days</label>
                                        <br>
                                        <InputNumber v-model="promonDefaultSnoozeDays" 
                                            id="promonDefaultSnoozeDays" class="mt-2 me-2" @focus="handleFocus($event)"
                                            :min="1"
                                            :max="999"
                                            @blur="handleBlur($event.originalEvent, InternalSettings.promon_default_snooze_days)"
                                            :disabled="isSavingItem(InternalSettings.promon_default_snooze_days)" />

                                        <loading-icon class="ms-2 d-inline-flex"
                                            v-if="isSavingItem(InternalSettings.promon_default_snooze_days)" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <form @submit.prevent="" class="my-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <label for="promon-dashboard-url">Dashboard URL</label>
                                        <div class="d-flex align-items-center mt-2">
                                            <InputText type="url" v-model="promonDashboardUrl" :disabled="isSavingItem(InternalSettings.promon_dashboard_url)"
                                                :data-property="InternalSettings.promon_dashboard_url"
                                                maxlength="2048" @focus="handleFocus($event)" id="promon-dashboard-url"
                                                class="mt-2 me-2 w-100" @blur="handleBlur($event)" />
                                            
                                            <a v-if="promonDashboardUrl" :href="getSettingUrl(promonDashboardUrl.toString())"
                                                target="_blank" title="Visit Page" class="d-inline-block"
                                                style="margin-right: 0.5em;">
                                                <i class="fa fa-external-link" aria-hidden="true"
                                                    aria-label="Visit Page"></i>
                                            </a>
                                            
                                            <loading-icon class="d-inline"
                                                v-if="isSavingItem(InternalSettings.promon_dashboard_url)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <!-- ######################################################## -->
                    <!--  APPVET -->
                    
                    <h3 class="config-header">Application Vetter</h3>

                    <form class="my-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row pt-2">
                                    <div class="col-12">
                                        <label for="appVetter">Availability</label>
                                        <br>
                                        <RadioButton v-model="enableAppVetter" class="mx-1 mt-2"
                                            inputId="appVetterEnable" :value="true" @change="updateAppVetter" />
                                        <label for="appVetterEnable" class="me-1">Enabled</label>

                                        <RadioButton v-model="enableAppVetter" class="mx-1 mt-2"
                                            inputId="appVetterDisable" :value="false" @change="updateAppVetter" />
                                        <label for="appVetterDisable">Disabled</label>

                                        <loading-icon class="ms-2 d-inline-flex"
                                            v-if="isSavingItem('application_vetter')" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <template v-if="enableAppVetter">
                        <form @submit.prevent="" class="my-3">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row pt-2">
                                        <div class="col-12">
                                            <label for="appvetDefaultSnoozeDays">Default Snooze
                                                Days</label>
                                            <br>
                                            <InputNumber v-model="appvetDefaultSnoozeDays" 
                                                id="appvetDefaultSnoozeDays" class="mt-2 me-2" @focus="handleFocus($event)"
                                                :min="1"
                                                :max="999"
                                                @blur="handleBlur($event.originalEvent, InternalSettings.application_default_snooze_days)"
                                                :disabled="isSavingItem(InternalSettings.application_default_snooze_days)" />

                                            <loading-icon class="ms-2 d-inline-flex"
                                                v-if="isSavingItem(InternalSettings.application_default_snooze_days)" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form class="my-3">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row pt-2">
                                        <div class="col-12">
                                            <label for="applicationProcessing">Application Processing</label>
                                            <br>
                                            <RadioButton v-model="applicationProcessing" class="mx-1 mt-2"
                                                inputId="applicationProcessingEnabled" value="enabled"
                                                @change="updateApplicationProcessing" />
                                            <label for="applicationProcessingEnabled" class="me-1">Enabled</label>

                                            <RadioButton v-model="applicationProcessing" class="mx-1 mt-2"
                                                inputId="applicationProcessingSuspended" value="suspended"
                                                @change="updateApplicationProcessing" />
                                            <label for="applicationProcessingSuspended">Suspended</label>

                                            <loading-icon class="ms-2 d-inline-flex"
                                                v-if="isSavingItem('application_processing')" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form @submit.prevent="" class="my-3">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label for="appIdPropertyName">Application ID Property Name</label>
                                            <div class="d-flex align-items-center mt-2">
                                                <InputText v-model="appIdPropertyName" maxlength="255"
                                                    id="appIdPropertyName" class="mt-2 me-2 w-100"
                                                    @focus="handleFocus($event)" @blur="handleBlur($event)"
                                                    :data-property="InternalSettings.app_id_property_name"
                                                    :disabled="isSavingItem(InternalSettings.app_id_property_name)" />
                                                <loading-icon class="d-inline ml-2"
                                                    v-if="isSavingItem(InternalSettings.app_id_property_name)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form @submit.prevent="" class="my-3">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label for="appVetDashboardUrl">Dashboard URL</label>
                                            <div class="d-flex align-items-center mt-2">
                                                <InputText type="url" v-model="appVetDashboardUrl" :disabled="isSavingItem(InternalSettings.appvetter_dashboard_url)"
                                                    :data-property="InternalSettings.appvetter_dashboard_url"
                                                    maxlength="2048" @focus="handleFocus($event)" id="appVetDashboardUrl"
                                                    class="mt-2 me-2 w-100" @blur="handleBlur($event)" />
                                                
                                                <a v-if="appVetDashboardUrl" :href="getSettingUrl(appVetDashboardUrl.toString())"
                                                    target="_blank" title="Visit Page" class="d-inline-block"
                                                    style="margin-right: 0.5em;">
                                                    <i class="fa fa-external-link" aria-hidden="true"
                                                        aria-label="Visit Page"></i>
                                                </a>
                                                <loading-icon class="d-inline"
                                                    v-if="isSavingItem(InternalSettings.appvetter_dashboard_url)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form> 
                    </template>  <!-- end container for appvet enabled settings -->
                    

                    <!-- ######################################################## -->
                    <!--  INVESTIGATION SERVICES -->

                    <h3 class="config-header">Investigation Services</h3>

                    <form class="my-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row pt-2">
                                    <div class="col-12">
                                        <label for="investigationServices">Availability</label>
                                        <br>
                                        <RadioButton v-model="enableInvestigationServices" class="mx-1 mt-2"
                                            inputId="investigationServicesEnable" :value="true"
                                            @change="updateInvestigationServices" />
                                        <label for="investigationServicesEnable" class="me-1">Enabled</label>

                                        <RadioButton v-model="enableInvestigationServices" class="mx-1 mt-2"
                                            inputId="investigationServicesDisable" :value="false"
                                            @change="updateInvestigationServices" />
                                        <label for="investigationServicesDisable">Disabled</label>

                                        <loading-icon class="ms-2 d-inline-flex"
                                            v-if="isSavingItem('investigation_services')" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                
                </div>  <!-- end wrapper for settings -->

                <div v-else>
                    <div class="card my-3" v-for="index in 6" :key="index">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <label for="skeleton">
                                        <Skeleton width="15rem" height="1rem" class="mb-2"></Skeleton>
                                    </label>
                                    <br>
                                    <div class="d-inline-flex">
                                        <Skeleton width="10rem" height="1rem" class="me-2"></Skeleton>
                                        <Skeleton width="10rem" height="1rem" class="me-2"></Skeleton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue"
import { toast } from "@/helpers/toast"
import { getApiErrorMessage, getCurrencyFormatter } from "@/helpers/common"
import { useAPI } from "@/helpers/services/api"
import { useAuth0 } from '@auth0/auth0-vue'
import LoadingIcon from "@/components/Shared/LoadingIcon.vue"
import { InternalSettings } from "@/helpers/common"
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber';
import RadioButton from 'primevue/radiobutton'
import { useAdminDetails } from "@/stores/adminDetails"
import Skeleton from "primevue/skeleton"
import { useProfile } from "@/stores/profile"
import type { InternalSettingsWithParameter } from "@/helpers/interface/general"
import { ClaimsFields } from '@/helpers/roles'


const api = useAPI()
const { user } = useAuth0();
const storeAdminDetails = useAdminDetails()
const storeProfile = useProfile()
const currentCustomerAccess = computed(() => (storeProfile.getCustomerAccess))
type TypeInternalSettings = boolean | string | null

// SETTINGS
const internalSettings = computed(() => storeAdminDetails.getInternalSettings)
const prioritizationModel = ref<TypeInternalSettings>("")
const applicationProcessing = ref<TypeInternalSettings>("")
const appIdPropertyName = ref<TypeInternalSettings>("")
const appVetDashboardUrl = ref<TypeInternalSettings>("")
const promonDashboardUrl = ref<TypeInternalSettings>("")
const enableBetaFeatures = ref<TypeInternalSettings>(null)
const enableAppVetter = ref<TypeInternalSettings>(null)
const enableInvestigationServices = ref<TypeInternalSettings>(null)
const promonListLayout = ref<any>(null)
const promonDefaultSnoozeDays = ref<any>(null)
const appvetDefaultSnoozeDays = ref<any>(null)

// OTHER STATE
const fetchInternalSettingsLoading = ref(true)
const currentItemOriginalValue = ref("")
const isSaving = ref(false)
const savingPropertyName = ref("")

const fetchInternalSettingsWithLoading = async () => {
    fetchInternalSettingsLoading.value = true
    await fetchInternalSettings()
    fetchInternalSettingsLoading.value = false
}

const getSettingValue = (name: string): TypeInternalSettings => (internalSettings.value.filter((s) => s.parameter === name)[0]?.value)
const isSavingItem = (name: string) => (isSaving.value && savingPropertyName.value === name)

const fetchInternalSettings = async () => {
    await storeAdminDetails.fetchInternalSettings(loadFailureHandler)
    applicationProcessing.value = getSettingValue("application_processing")
    prioritizationModel.value = getSettingValue("prioritization_model")
    appIdPropertyName.value = getSettingValue("app_id_property_name")
    appVetDashboardUrl.value = getSettingValue("appvetter_dashboard_url")
    promonDashboardUrl.value = getSettingValue(InternalSettings.promon_dashboard_url)
    enableBetaFeatures.value = getSettingValue("enable_beta_features")
    promonDefaultSnoozeDays.value = getSettingValue("promon_default_snooze_days")
    appvetDefaultSnoozeDays.value = getSettingValue("application_default_snooze_days")

    enableAppVetter.value = currentCustomerAccess.value?.application_vetter as boolean
    enableInvestigationServices.value = currentCustomerAccess.value?.investigation_services as boolean
    promonListLayout.value = getSettingValue("promon_list_layout")

    if (promonListLayout.value?.[0]) {
        promonListLayout.value = promonListLayout.value[0]?.columns[0]?.fields[0]?.field_name || null
    }
}

const updatePrioritizationModel = async () => updateSetting(InternalSettings.prioritization_model, prioritizationModel.value as InternalSettings)
const updateApplicationProcessing = async () => updateSetting(InternalSettings.application_processing, applicationProcessing.value as InternalSettings)
const updateBetaFeatures = async () => updateSetting(InternalSettings.enable_beta_features, enableBetaFeatures.value as InternalSettings)
const updateAppVetter = async () => updateSetting(InternalSettings.application_vetter, enableAppVetter.value as InternalSettings)
const updateInvestigationServices = async () => updateSetting(InternalSettings.investigation_services, enableInvestigationServices.value as InternalSettings)
const updatePromonListLayout = async () => updateSetting(InternalSettings.promon_list_layout, promonListLayout.value as InternalSettings)

const handleFocus = (event: FocusEvent) => {
    const input = event.target as HTMLInputElement
    currentItemOriginalValue.value = input.value
}

const handleBlur = (event: FocusEvent, property: string = "") => {
    const input = event.target as HTMLInputElement
    if (input.value !== currentItemOriginalValue.value) {
        const name = input.dataset?.property || property
        if (!name) {
            alert("Configuration error. Element is not configured with a property name")
            return
        }
        updateSetting(name, input.value as InternalSettings)
    }
}

const updateSetting = async (settingsName: string, value: InternalSettings) => {
    savingPropertyName.value = settingsName
    isSaving.value = true
    const customerServices = ["application_vetter", "investigation_services"]
    const listLayout = ["promon_list_layout"]

    try {
        if (customerServices.includes(settingsName)) {
            const payload = {
                "application_vetter": enableAppVetter.value,
                "investigation_services": enableInvestigationServices.value
            }
            await api.patch(`/customers/`, payload)
        }

        else {
            let payload
            if (listLayout.includes(settingsName)) {
                payload = [
                    {
                        "view_name": "inbox",
                        "columns": [
                            {
                                "fields": [
                                    {
                                        "field_name": value
                                    }
                                ],
                                "column_index": 4
                            }
                        ]
                    }
                ] as InternalSettingsWithParameter[]
            }
            else payload = value

            await api.patch(`/settings/internal/${settingsName}`, { "value": payload })

        }
        toast.success("Saved!")
        storeProfile.fetchCustomerAccess()
    } catch (error: any) {
        toast.error(getApiErrorMessage(error))
    }

    isSaving.value = false
    savingPropertyName.value = ""
}

const loadFailureHandler = (error: any) => {
    const message = getApiErrorMessage(error, { "featureName": "Config: Settings" })
    toast.error(message)
}

const getSettingUrl = (templateUrl: string) => {
    if (templateUrl) {
        return templateUrl.replace("{customer_id}", getCustomerId().toString())
    }
    return templateUrl
}

const getCustomerId = () => {
    const output = ~~(user.value?.[ClaimsFields["app_metadata"]]?.customer_id)
    return output
}

onMounted(async () => {
    await fetchInternalSettingsWithLoading()
})

</script>
