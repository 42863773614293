<template>
    <div class="element-container">
        <label :for="getId('address')" :class="labelClasses">
            {{ props.text }} Address (street number and name)
        </label>
        <div v-if="!allowPoBox" class="element-description">PO Box numbers cannot be accepted.</div>
        <input type="text" :id="getId('address')" maxlength="255" placeholder="ex. 123 Main Street"
            autocomplete="street-address" spellcheck="false" v-model="address"
            title="A physical address, for example '123 Main Street'. PO Box cannot be accepted." :required="required"
            @invalid.capture.prevent="elementRequiredHandler">
    </div>
    <div class="element-container">
        <label :for="getId('unit-number')" class="form-label">
            Unit Number
        </label>
        <input type="text" :id="getId('unit-number')" v-model="unitNumber" maxlength="10" placeholder="ex. #157"
            spellcheck="false">
    </div>
    <div class="element-container">
        <label :for="getId('city')" :class="labelClasses">
            City
        </label>
        <input type="text" :id="getId('city')" maxlength="75" v-model="city" autocomplete="address-level2"
            spellcheck="false" :required="required" @invalid.capture.prevent="elementRequiredHandler">
    </div>
    <div class="element-container">
        <label :for="getId('state')" :class="labelClasses">
            State
        </label>
        <input type="text" :id="getId('state')" maxlength="2" v-model="state" autocomplete="address-level1"
            autocapitalize="characters" spellcheck="false" :required="required"
            @invalid.capture.prevent="elementRequiredHandler">
    </div>
    <div class="element-container">
        <label :for="getId('postal-code')" :class="labelClasses">
            Postal Code
        </label>
        <input type="text" :id="getId('postal-code')" maxlength="5" v-model="postalCode" autocomplete="postal-code"
            spellcheck="false" :required="required" @invalid.capture.prevent="elementRequiredHandler">
    </div>
    <div v-if="showCounty" class="element-container">
        <label :for="getId('county')" :class="labelClasses">
            County
        </label>
        <input type="text" :id="getId('county')" maxlength="75" v-model="county" spellcheck="false" :required="required"
            @invalid.capture.prevent="elementRequiredHandler">
    </div>
</template>

<script setup lang="ts">
import type { PropType } from "vue"

const props = defineProps({
    text: {
        type: String,
        required: true
    },
    showCounty: {
        type: Boolean,
        default: false
    },
    allowPoBox: {
        type: Boolean,
        default: true
    },
    required: {
        type: Boolean,
        default: false
    },
    fieldCode: {
        type: String,
        required: true,
    },
    elementRequiredHandler: {
        type: Function as PropType<(e: Event) => void>,
        required: true
    },
})

const labelClasses = ["form-label"]
props.required && labelClasses.push("form-label-required")

const ID_PREFIX = props.text.replace(" ", "-").toLowerCase().trim() + "-"
const getId = (text: string) => {
    return ID_PREFIX + text
}

const address = defineModel<string | null>("address")
const unitNumber = defineModel<string | null>("unitNumber")
const city = defineModel<string | null>("city")
const state = defineModel<string | null>("state")
const postalCode = defineModel<string | null>("postalCode")
const county = defineModel<string | null>("county")

</script>