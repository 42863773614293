<template>
    <div id="report-page-header">
        <div class="row p-3">
            <div class="col-4" :class="'text-' + props.logoPosition">
                <img :src="props.logoPath" alt="Logo" class="logo">
            </div>
            <div class="col-8">
                <h1 class="ms-4"><b>{{ props.headerText }}</b></h1>
            </div>
        </div>

    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    logoPosition: {
        type: String,
        default: "right"
    },
    logoPath: {
        type: String,
        default: "/images/tr_icon.png"
    },
    headerText: {
        type: String,
        default: ""
    }
})
</script>

<style>
#report-page-header .row {
    background-color: #fff;
    align-items: center;
    background-image: url("/images/report_page_bg.png");
    background-size: cover;
    width: 100%;
}

#report-page-header img {
    width: 150px !important;
}
</style>