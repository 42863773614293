<template>
  <div class="container">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Published</th>
          <th scope="col">Published Version ID</th>
          <th scope="col">Client</th>
          <th scope="col">Edit</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="state.configsLoading">
          <td colspan="6" style="text-align: center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </td>
        </tr>
        <tr v-for="config in state.configs" :key="config.id">
          <td><router-link :to="{name: 'onboardingConfig', params: { id: config.id }}">{{ config.id }}</router-link></td>
          <td>{{ new Date(config.published_at!).toDateString() }}</td>
          <td>{{ config.version_id }}</td>
          <td>{{ getCustomerById(config?.customer_id)?.name || `#${config.customer_id}` }}</td>
          <td>
            <button type="button"
                    class="btn btn-outline-primary btn-sm"
                    @click="$router.push({name: 'onboardingConfig', params: { id: config.id }})"
            >Edit</button>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <button type="button" class="btn btn-outline-primary btn-sm" @click="$router.push({name: 'onboardingConfig', params: { id: 'new' }})">New Config</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { useAPI } from "@/helpers/services/api"
import {reactive, onMounted } from "vue";
import type {
  Customer,
} from "@/helpers/interface/trueload";
import type {
  Config
} from "@/helpers/interface/onboarding";

const state = reactive({
  configs: [] as Array<Config>,
  configsLoading: false,
  customers: [] as Array<Customer>,
  customersLoading: false,
})

const getCustomerById = (id: string | number): Customer | undefined => {
  return state?.customers?.find(customer => customer.customer_id == id)
}
const api = useAPI()

const getCustomers = async () => {
  state.customersLoading = true;
  const response = await api.get(`customers/`);
  state.customers = response.data;
  state.customersLoading = false;
}

const getConfigs = async () => {
  state.configsLoading = true;
  const response = await api.get(`onboarding/configs/`);
  state.configs = response.data;
  state.configsLoading = false;
}

onMounted(async () => {
  await Promise.all([
    getCustomers(),
    getConfigs(),
  ])
});

</script>

<style scoped></style>
