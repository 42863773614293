<template>
    <div>
        <h2 class="config-header">Report: New Assessed Value Estimate</h2>

        <form @submit.prevent="onSubmitHandler" style="max-width: 900px;">
            <div class="row pt-2">
                <div class="col-3">
                    <label for="avreport-filter-date-type" class="d-block">Date Range</label>
                    <select id="avreport-filter-date-type" v-model="dateRangeView" class="search-field">
                        <option value="currentYear" selected>Year to Date</option>
                        <option value="previousYear">Previous Year</option>
                        <option value="all">All Years</option>
                        <option value="customRange">Custom Range</option>
                    </select>
                </div>

                <div class="col-3" v-if="dateRangeView === 'customRange'">
                    <label for="avreport-filter-date-from" class="d-block">Start Date <span
                            class="required">*</span></label>
                    <input type="date" id="avreport-filter-date-from" v-model="searchOptions.dateFrom"
                        class="search-field" required>
                </div>

                <div class="col-3" v-if="dateRangeView === 'customRange'">
                    <label for="avreport-filter-date-to" class="d-block">End Date <span
                            class="required">*</span></label>
                    <input type="date" id="avreport-filter-date-to" v-model="searchOptions.dateTo" class="search-field"
                        required>
                </div>
            </div>

            <div class="row pt-2">
                <a id="avreport-show-more-filters-btn" href="javascript:;" @click="showMoreFilters = !showMoreFilters" style="color: var(--general-text-color);">
                    <span v-if="!showMoreFilters"><i class="fa fa-caret-right"></i> More Options</span>
                    <span v-else><i class="fa fa-caret-up"></i> Less Options</span>
                </a>
            </div>


            <div class="row pt-2" v-if="showMoreFilters">
                <div class="col-3">
                    <label for="avreport-filter-exemption-code" class="d-block">Exemption</label>
                    <select id="avreport-filter-exemption-code" v-model="searchOptions.exemption" class="search-field">
                        <option value="" selected>-- Select --</option>
                        <option v-for="item in exemptionCodes" :value="item.exemption_code" :key="item.exemption_code">
                            {{ item.description }}
                        </option>
                    </select>
                </div>

                <div class="col-3">
                    <label for="avreport-filter-exemption-type" class="d-block">Exemption Type</label>
                    <select id="avreport-filter-exemption-type" v-model="searchOptions.exemptionType"
                        class="search-field">
                        <option value="" selected>-- Select --</option>
                        <option v-for="exType in exemptionTypes" :value="exType.value" :key="exType.value">
                            {{ exType.text }}
                        </option>
                    </select>
                </div>

                <div class="col-3">
                    <label for="avreport-filter-queue" class="d-block">Queue</label>
                    <select id="avreport-filter-queue" v-model="searchOptions.queue" @change="onQueueChangeHandler"
                        class="search-field">
                        <option value="" selected>-- Select --</option>
                        <option v-for="queue in queueDropdownOptions" :value="queue.value" :key="queue.value">
                            {{ queue.text }}
                        </option>
                    </select>
                </div>

                <div class="col-3" v-if="searchOptions.queue">
                    <label for="avreport-filter-reason" class="d-block">Reason</label>
                    <select id="avreport-filter-reason" v-model="searchOptions.reason" class="search-field">
                        <option value="" selected>--Select --</option>
                        <option v-for="reason in reasons" :value="reason" :key="reason">
                            {{ reason }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row">
                <div class="col-6 mt-4 d-flex">
                    <button type="submit" class="searchButton" :disabled="isLoading">Refresh Report</button>
                    <button type="button" class="clearButton" :disabled="isLoading" v-if="showMoreFilters"
                        @click="showClearConfirmDialog = true">
                        Clear Options
                    </button>
                    <loading-icon v-if="isLoading"></loading-icon>
                </div>
            </div>
        </form>

        <div class="report-summary-container" id="avreport-totals-container">
            <h4 class="text-center mb-4">Summary</h4>
            <table class="table">
                <thead>
                    <tr>
                        <th>Queue</th>
                        <th class="text-right">Parcels</th>
                        <th class="text-right">Amount Recovered</th>
                        <th class="text-right">Lien Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="showUnqualifiedTotals">
                        <td>Unqualified</td>
                        <td class="text-right">{{ totals.parcels_unqualified || 0 }}</td>
                        <td class="text-right">{{ totals.recovered_amount_unqualified }}</td>
                        <td class="text-right">{{ totals.lien_amount_unqualified }}</td>
                    </tr>
                    <tr v-if="showArchiveTotals">
                        <td>Archive</td>
                        <td class="text-right">{{ totals.parcels_archive || 0 }}</td>
                        <td class="text-right">{{ totals.recovered_amount_archive }}</td>
                        <td class="text-right">{{ totals.lien_amount_archive }}</td>
                    </tr>
                    <tr v-if="showUnqualifiedTotals && showArchiveTotals" class="report-summary-row-total">
                        <td>Total</td>
                        <td class="text-right">{{ totals.parcels_unqualified + totals.parcels_archive }}</td>
                        <td class="text-right">{{ totals.recovered_total }}</td>
                        <td class="text-right">{{ totals.lien_amount_total as any }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col d-block">
                <div class="d-inline-flex">
                    <button type="button" @click="exportReport" class="actionButton"
                        :disabled="exportCSVLoading || isLoading">
                        <span class="fa fa-download" title="Download CSV" aria-hidden="true"></span>
                        Download Report
                    </button>
                    <loading-icon v-if="exportCSVLoading" />
                </div>
            </div>
        </div>
        <!-- tabulator -->
        <div class="mt-3" id="avreport-list"></div>
    </div>

    <teleport to="body">
        <ModalDialog v-if="showClearConfirmDialog" title="Confirm Clear Options"
            :close="() => showClearConfirmDialog = false">
            <p>Are you sure you want to clear the selected options?</p>
            <template #footer>
                <button @click="onClearOptionsHandler"
                    class="btn btn-danger confirmClearSearch dialogButton">Confirm</button>
                <button @click="showClearConfirmDialog = false" class="btn btn-default dialogButton">Close</button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="editNAVRecord != null" :title="`Edit Parcel ${editNAVRecord.parcel_num_standardized}`"
            :close="() => editNAVRecord = null">
            <form @submit.prevent="editRecoveredLienAmount" id="editNAVRecord">
                <div class="row">
                    <div class="col-12">
                        <label for="recoveredAmount">
                            Estimated Recovered Amount
                            <span class="required">*</span>
                            <abbr class="filter-tooltip" :title="RECOVERED_AMOUNT_TOOLTIP">
                                <img src="/images/tooltip_icon_blue_outlined.png" alt="hint icon">
                            </abbr>
                        </label>
                        <input type="text" inputmode="decimal" pattern="[0-9]+(\.[0-9][0-9])?"
                            class="cbFormTextField cbFormTextField-xl" v-model="editNAVRecord.recovered_amount"
                            maxlength="15" :title="MONETARY_INPUT_TOOLTIP" id="recoveredAmount" required>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-2">
                        <label for="lienAmount">
                            Back Tax / Lien Amount
                            <abbr class="filter-tooltip" title="For amount recovered via lien">
                                <img src="/images/tooltip_icon_blue_outlined.png" alt="hint icon">
                            </abbr>
                        </label>
                        <input type="text" inputmode="decimal" pattern="[0-9]+(\.[0-9][0-9])?"
                            class="cbFormTextField cbFormTextField-xl" v-model="editNAVRecord.lien_amount"
                            maxlength="15" id="lienAmount" :title="MONETARY_INPUT_TOOLTIP">
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 mt-3 d-flex">
                        <button :disabled="(editNAVRecordLoading)" id="bulkUpdateForm" class="searchButton">
                            <span class="fa fa-edit" aria-hidden="true"></span>
                            Update
                        </button>
                        <loading-icon v-if="editNAVRecordLoading" />
                    </div>
                </div>
            </form>
        </ModalDialog>
    </teleport>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { useAPI } from '@/helpers/services/api';
import { getApiErrorMessage, getCurrencyFormatter, downloadCSV, RECOVERED_AMOUNT_TOOLTIP, MONETARY_INPUT_TOOLTIP } from '@/helpers/common';
import type { AxiosError } from 'axios';
import type { Tabulator, ColumnDefinition, CellComponent } from 'tabulator-tables';
import LoadingIcon from "@/components/Shared/LoadingIcon.vue"
import ModalDialog from "@/components/Shared/ModalDialog.vue";
import { createAjaxTabulator, destroyTabulator, createAjaxConfig } from '@/helpers/true-tabulator';
import dayjs from 'dayjs';
import { exemptionTypes, queueOptions } from '@/helpers/dropdownOptions';
import type { ExemptionCode } from '@/helpers/interface/candidates';
import type { ReportRecord, TotalsRecord, QueryParameters, EditRecoveredLienAmount } from '@/helpers/types/newAssessedValue';
import { toast } from '@/helpers/toast';

const BASE_URL = import.meta.env.VITE_DEV_API_URL
const API_PATH_DETAILS = `${BASE_URL}/reports/new-assessed-value`
const API_PATH_TOTALS = API_PATH_DETAILS + "/total"
const CALC_PLACEHOLDER = "Calculating..."

const api = useAPI()
const { getAccessTokenSilently } = useAuth0()
const exportCSVLoading = ref(false)
const exportCSVFilename = ref("")
const queueCodes = ["unqualified", "archive"]
const queueDropdownOptions = queueOptions.filter(option => queueCodes.includes(option.value))

const reasonsCache = queueCodes.reduce((obj, key) => {
    obj[key] = []
    return obj;
}, {} as Record<string, string[]>)


const editNAVRecordLoading = ref(false)
const editNAVRecord = ref<EditRecoveredLienAmount | null>(null)
const now = new Date()

const currencyFormatter = getCurrencyFormatter()

const defaultSearchOptions = {
    "exemption": "",
    "exemptionType": "",
    "dateFrom": `${now.getFullYear()}-01-01`,
    "dateTo": now.toISOString().substring(0, 10),
    "queue": "",
    "reason": "",
}

const defaultTotals = {
    "recovered_amount_archive": CALC_PLACEHOLDER,
    "recovered_amount_unqualified": CALC_PLACEHOLDER,
    "recovered_total": CALC_PLACEHOLDER,
    "parcels_archive": 0,
    "parcels_unqualified": 0,
    "lien_amount_archive": CALC_PLACEHOLDER,
    "lien_amount_unqualified": CALC_PLACEHOLDER,
    "lien_amount_total": CALC_PLACEHOLDER
} as TotalsRecord

const exemptionCodes = ref([] as ExemptionCode[])
const reasons = ref([] as string[])
const searchOptions = ref({ ...defaultSearchOptions })
const totals = ref({ ...defaultTotals })
const showUnqualifiedTotals = ref(true)
const showArchiveTotals = ref(true)
const dateRangeView = ref("currentYear")
const showMoreFilters = ref(false)
const showClearConfirmDialog = ref(false)
const isLoading = ref(false)

let tabulator: Tabulator | null;

const currencyColumnFormatter = (cell: CellComponent) => {
    const value = cell.getValue()
    if (typeof value !== "number")
        return ""
    return currencyFormatter.format(value)
}


const tableColumns: ColumnDefinition[] = [
    {
        title: "Parcel",
        field: "parcel_num_standardized",
        formatter: (cell: CellComponent) => {
            const data = cell.getData() as ReportRecord
            return createCandidateLink(data)
        }
    },
    {
        title: "Queue",
        field: "queue",
        cssClass: "text-capitalize",
        formatter: (cell: CellComponent) => {
            const data = cell.getData() as ReportRecord
            const changeDate = dayjs(data.last_queue_change).format("MM/DD/YYYY")
            const title = `Date: ${changeDate}\nReason: ${data.reason || ""}`
            return createCellContentWithTitle(data.queue, title)
        }
    },
    {
        title: "Unqualified Start",
        field: "unqualified_start_year"
    },
    {
        title: "Lien/Back Taxes",
        field: "lien_or_back_taxes",
        formatter: (cell: CellComponent) => {
            const value = cell.getValue()
            return value ? "Yes" : "No"
        }
    },
    {
        title: "Market",
        field: "market_value",
        headerHozAlign: "right",
        hozAlign: "right",
        formatter: currencyColumnFormatter
    },
    {
        title: "Assessed",
        field: "assessed_value",
        headerHozAlign: "right",
        hozAlign: "right",
        formatter: currencyColumnFormatter
    },
    {
        title: "Taxable",
        field: "taxable_value",
        headerHozAlign: "right",
        hozAlign: "right",
        formatter: currencyColumnFormatter
    },
    {
        title: "AV - TV",
        field: "assessed_value_diff",
        headerHozAlign: "right",
        hozAlign: "right",
        formatter: currencyColumnFormatter
    },
    {
        title: "MV - TV",
        field: "market_value_diff",
        headerHozAlign: "right",
        hozAlign: "right",
        formatter: currencyColumnFormatter
    },
    {
        title: "Recovered",
        field: "recovered_amount",
        headerHozAlign: "right",
        hozAlign: "right",
        formatter: currencyColumnFormatter
        // In an upcoming sprint, we will use the actual "recovered_amount" field
        // formatter: currencyColumnFormatter 
    },
    {
        title: "Lien Amount",
        field: "lien_amount",
        headerHozAlign: "right",
        hozAlign: "right",
        formatter: currencyColumnFormatter,
    },
    {
        title: "&nbsp;",
        field: "updated_at",
        headerSort: false,
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as ReportRecord
            const edit: EditRecoveredLienAmount = {
                tru_id: data.tru_id,
                recovered_amount: data.recovered_amount as number,
                lien_amount: data.lien_amount as number,
                parcel_num_standardized: data.parcel_num_standardized,
            }
            const editButton = document.createElement("a")
            editButton.innerHTML = "<span class='fa fa-edit' aria-hidden='true'></span> Edit"
            editButton.classList.add("edit-btn")
            editButton.href = "javascript:;"
            editButton.addEventListener("click", () => editNAVRecord.value = edit)
            return editButton;
        },
    }

]

tableColumns.forEach((column) => {
    column.width = (100 / tableColumns.length).toFixed(2) + "%"
    column.headerSort = false
})


const onSubmitHandler = () => {
    refreshReport()
}


const onQueueChangeHandler = () => {
    searchOptions.value.reason = ""
    fetchReasons()
}


const onClearOptionsHandler = () => {
    // clear everything except date to/from, which are required
    searchOptions.value = {
        ...defaultSearchOptions,
        "dateFrom": searchOptions.value.dateFrom,
        "dateTo": searchOptions.value.dateTo
    }
    showClearConfirmDialog.value = false
}


const handleRequestError = (error: AxiosError) => {
    const message = getApiErrorMessage(error)
    toast.error(message)
}


const getQueryParameters = (): QueryParameters => {
    const params: QueryParameters = {};

    if (searchOptions.value.dateFrom)
        params.date_from = new Date(searchOptions.value.dateFrom)

    if (searchOptions.value.dateTo) {
        params.date_to = new Date(searchOptions.value.dateTo)
    }

    if (showMoreFilters.value) {
        if (searchOptions.value.exemption)
            params.exemption_codes = [searchOptions.value.exemption]

        if (searchOptions.value.exemptionType)
            params.exemption_types = [searchOptions.value.exemptionType]

        if (searchOptions.value.queue) {
            params.queue = searchOptions.value.queue

            if (searchOptions.value.reason)
                params.reason = searchOptions.value.reason
        }
    }


    return params
}

const getQueryString = (params: QueryParameters) => {
    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
        if (!value)
            return
        if (Array.isArray(value)) {
            searchParams.append(key, value.join(","));
        } else if (value instanceof Date) {
            searchParams.append(key, value.toISOString().substring(0, 10));
        } else {
            searchParams.append(key, value.toString());
        }
    });

    return searchParams.toString()
}


const createCandidateLink = (data: ReportRecord) => {
    const a = document.createElement("a")
    a.href = "/candidate-details/" + data.tru_id
    a.target = "_blank"
    a.classList.add("candidate-list-link")
    a.textContent = data.parcel_num_standardized
    return a
}


const createCellContentWithTitle = (text: string, title: string) => {
    const el = document.createElement("span")
    el.textContent = text
    el.setAttribute("title", title)
    return el
}


const fetchExemptionCodes = () => {
    api.get("taxroll/exemption_codes")
        .then(response => {
            exemptionCodes.value = response.data || []
        })
        .catch(error => {
            exemptionCodes.value = []
            handleRequestError(error)
        })
}


const fetchReasons = async () => {
    const queue = searchOptions.value.queue

    if (!queue) {
        reasons.value = []
        return
    }

    /* archive actually uses "final_outcome", not "reason", 
        but this UI pretends they're the same.  If archive is chosen,
        just provide "Exemption Removed" option, because that's all 
        this report takes into account
    */
    if (queue === "archive") {
        reasonsCache[queue] = ["Exemption Removed"]
    }

    if (queue in reasonsCache && reasonsCache[queue].length) {
        reasons.value = reasonsCache[queue]
        return
    }

    api.get(`taxroll/reasons?category=${encodeURIComponent(queue)}`)
        .then(response => {
            const items = response.data?.data?.reasons || []
            reasons.value = items as string[]
            reasonsCache[queue] = items as string[]
        })
        .catch(error => {
            reasons.value = []
            handleRequestError(error)
        })
}


const fetchReportDetails = async () => {
    if (tabulator) {
        tabulator.clearAlert()
        tabulator.setData(API_PATH_DETAILS)
        return
    }

    const accessToken = await getAccessTokenSilently()
    const ajaxConfig = createAjaxConfig(accessToken)
    ajaxConfig.method = "GET"

    tabulator = await createAjaxTabulator("#avreport-list", {
        ajaxConfig: ajaxConfig,
        ajaxURLGenerator: (url, config, params) => {
            typeof config;
            const filterParams = getQueryParameters();
            const queryString = getQueryString(filterParams);
            return `${url}?page=${params.page}&page_size=${params.page_size}&${queryString}`;
        },
        columns: tableColumns,
        pagination: true,
    })

    if (!tabulator) return
    tabulator.on("tableBuilt", () => {
        if (!tabulator) return
        tabulator.setData(API_PATH_DETAILS)
    })
}


const fetchReportTotals = async () => {
    isLoading.value = true
    totals.value = { ...defaultTotals }

    const params = getQueryParameters()
    const queryString = getQueryString(params)

    api.get(API_PATH_TOTALS + "?" + queryString)
        .then(response => {
            totals.value = response.data as TotalsRecord

            totals.value = {
                ...totals.value,
                recovered_amount_archive: currencyFormatter.format(totals.value.recovered_amount_archive as any),
                recovered_amount_unqualified: currencyFormatter.format(totals.value.recovered_amount_unqualified as any),
                recovered_total: currencyFormatter.format(totals.value.recovered_total as any),
                lien_amount_archive: currencyFormatter.format(totals.value.lien_amount_archive as any),
                lien_amount_unqualified: currencyFormatter.format(totals.value.lien_amount_unqualified as any),
                lien_amount_total: currencyFormatter.format(totals.value.lien_amount_total as any),
            }
            const queue = searchOptions.value.queue
            showArchiveTotals.value = !queue || queue === "archive"
            showUnqualifiedTotals.value = !queue || queue === "unqualified"

            isLoading.value = false
        })
}

const updateExportCSVFilename = () => {
    const params = getQueryParameters()
    let filename: string = "Assessed-Value"
    if (dateRangeView.value === 'currentYear') filename = filename + "-Year-to-Date"
    else if (dateRangeView.value === 'previousYear') filename = filename + "-Previous-Year"
    else if (dateRangeView.value === 'all') filename = filename + "-All-Years"
    else if (dateRangeView.value === 'customRange') {
        const date_from = dayjs(params.date_from).format("YYYY-MM-DD")
        const date_to = dayjs(params.date_to).format("YYYY-MM-DD")
        filename = filename + `-${date_from}-to-${date_to}`
    }
    exportCSVFilename.value = filename
}

const exportReport = async () => {
    exportCSVLoading.value = true
    const params = getQueryParameters()
    const queryString = getQueryString(params)
    try {
        const response = await api.get(API_PATH_DETAILS + "/export?" + queryString)
        downloadCSV(response.data, exportCSVFilename.value)
    } catch (error: unknown) {
        const err = error as AxiosError
        toast.error(getApiErrorMessage(err))
    }
    exportCSVLoading.value = false
}


const editRecoveredLienAmount = async () => {
    editNAVRecordLoading.value = true
    if (!editNAVRecord.value) return
    const { ...data } = editNAVRecord.value;
    try {
        await api.patch(`/taxroll/recovered_amount`, data)
        await refreshReport()
        toast.success(`Your update has been saved`)
        editNAVRecord.value = null
    } catch (error: unknown) {
        const err = error as AxiosError
        toast.error(getApiErrorMessage(err))
    }
    editNAVRecordLoading.value = false;
}

const refreshReport = async () => {
    fetchReportTotals()
    fetchReportDetails()
    updateExportCSVFilename()
}


watch(() => dateRangeView.value, (newValue: string) => {
    const now = new Date()

    const lastYearEnd = new Date(now.getFullYear() + "-01-01")
    lastYearEnd.setDate(lastYearEnd.getDate() - 1)

    switch (newValue) {
        case "currentYear":
            searchOptions.value.dateFrom = `${now.getFullYear()}-01-01`
            searchOptions.value.dateTo = defaultSearchOptions.dateTo
            break;
        case "previousYear":
            searchOptions.value.dateFrom = `${now.getFullYear() - 1}-01-01`
            searchOptions.value.dateTo = lastYearEnd.toISOString().substring(0, 10)
            break;
        case "all":
            searchOptions.value.dateFrom = "2000-01-01"
            searchOptions.value.dateTo = defaultSearchOptions.dateTo
            break;
        default:
            // Showing custom date range. Use whatever from/to dates are set
            break;
    }
})
onMounted(() => {
    fetchExemptionCodes()
    refreshReport()
})

onUnmounted(() => destroyTabulator(tabulator))

</script>

<style>
.report-summary-container {
    border: 3px solid #e6e6e6;
    padding: 1em;
    border-radius: 3px;
    max-width: 660px;
    margin: 2em 0;
    font-size: 1.2em;
}

.report-summary-row-total {
    font-weight: bold;
}
</style>
