<template>
    <div class="loading-icon">
        <div class="fa fa-spinner fa-spin"></div>
        <slot></slot>
    </div>
</template>
  
<style scoped>
.loading-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

circle {
    fill: transparent;
    stroke: #007bff;
    stroke-width: 10;
    stroke-linecap: round;
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>