<template>
    <main class="p-2">
        <component :is="selectedPageComponent" />
    </main>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import AdminConfigChangeCustomer from './AdminConfigChangeCustomer.vue';
import AdminConfigUsers from './AdminConfigUsers.vue';
import AdminUpdateArchivedCan from './AdminUpdateArchivedCan.vue';
import AdminWorkflowMailModule from './AdminWorkflowMailModule.vue';
import AdminConfigMailTemplates from './AdminConfigMailTemplates.vue';
import AdminConfigLabels from './AdminConfigLabels.vue';
import AdminUnclaimedExmptDataRequest from './AdminUnclaimedExmptDataRequest.vue';
import AdminUpdateAssignCandidate from './AdminUpdateAssignCandidate.vue';
import AdminDownloadUnclaimedList from './AdminDownloadUnclaimedList.vue';
import AdminOnboarding from './AdminOnboarding.vue';
import AdminReportNewAssessedValue from './AdminReportNewAssessedValue.vue';
import AdminReportNewAssessedValueRequest from './AdminReportNewAssessedValueRequest.vue';
import AdminConfigSettings from './AdminConfigSettings.vue';
import AdminUserPreference from './AdminUserPreference.vue';

const props = defineProps({
    page: {
        type: String,
        required: true
    }
})

const pageComponents: any = {
    update_archived_candidates: AdminUpdateArchivedCan,
    config_change_customer: AdminConfigChangeCustomer,
    config_users: AdminConfigUsers,
    workflow_mail_module: AdminWorkflowMailModule,
    config_mail_templates: AdminConfigMailTemplates,
    config_labels: AdminConfigLabels,
    unclaimed_exmpt_data_req: AdminUnclaimedExmptDataRequest,
    update_assign_candidate: AdminUpdateAssignCandidate,
    dl_unclaimed_list: AdminDownloadUnclaimedList,
    onboarding: AdminOnboarding,
    report_new_assessed_value: AdminReportNewAssessedValue,
    report_new_assessed_value_request: AdminReportNewAssessedValueRequest,
    config_settings: AdminConfigSettings,
    user_preference: AdminUserPreference,
}
const selectedPageComponent = computed(() => pageComponents[props.page]);

</script>

<style>
@import "@/assets/admin-page.css";
</style>