interface RoleMap {
    [key: string]: string;
}

export const ClaimsFields = {
    "roles": "https://trueroll.io/roles",
    "app_metadata": "https://trueroll.io/app_metadata",
    "nickname": "nickname",
    "name": "name",
    "picture": "picture",
    "updated_at": "updated_at",
    "email": "email",
    "email_verified": "email_verified",
    "sub": "sub"
}

export const Roles: RoleMap = {
    "client": "Client",
    "client_admin": "Client Admin",
    "client_investigator": "Client Investigator",
    "client_specialist": "Client Specialist",
    "trueroll_investigation_manager": "TrueRoll Investigation Manager",
    "trueroll_admin": "TrueRoll Admin",
    "trueroll_employee": "TrueRoll Employee",
    "trueroll_investigator": "TrueRoll Investigator",
    "trueroll_onboarding_engineer": "TrueRoll Onboarding Engineer",
}