<template>
  <div v-html="renderedHtml" class="markdown-view"></div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { sanitizeHTML, convertLineBreaksToHTML } from '@/helpers/common';
import { marked } from "marked";


const props = defineProps(['modelValue'])
const renderedHtml = computed(() => {
  return props.modelValue ? sanitizeHTML(convertLineBreaksToHTML(marked.parseInline(props.modelValue))) : ""
})
</script>

<style scoped>
.markdown-view {
  padding: .375rem .75rem;
  min-height: calc(1.5em + .75rem + 2px);
  line-height: 1.5;
}
</style>
