<template>
    <main>
        <p>Page Not Found</p>
    </main>
</template>

<style scoped>
main {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin: 100px;
}
</style>