import { computed, ref } from "vue"
import { defineStore } from "pinia"
import type { SupportingDocument } from "@/helpers/interface/trueapp"
import { useAPI } from "@/helpers/services/api"

export const useTaxroll = defineStore("taxroll", () => {

    const api = useAPI()
    const quickSearchQuery = ref("")
    const setQuickSearchQuery = (qs: string) => quickSearchQuery.value = qs
    const getQuickSearchQuery = computed(() => quickSearchQuery.value)
    const documentList = ref<SupportingDocument[]>([])
    const fetchDocumentListLoading = ref(true)

    const fetchDocumentList = async (tru_id: string, errorHandler: Function) => {
        fetchDocumentListLoading.value = true

        try {
            const { data } = await api.get(`applications/supporting_documents/${tru_id}`)
            documentList.value = data
        }  
        catch (error: unknown) {
            errorHandler(error)
        }
        fetchDocumentListLoading.value = false
    }

    const getDocumentList = computed(() => documentList.value)
    const getDocumentListLoadingState = computed(() => fetchDocumentListLoading.value)

    return {
        setQuickSearchQuery,
        getQuickSearchQuery,
        fetchDocumentList,
        getDocumentListLoadingState,
        getDocumentList
    }
})
