<template>
	<div id="owner-quick-links" class="quick-links-table"></div>
	<div id="property-quick-links" class="quick-links-table"></div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
import type { TabulatorFull as Tabulator } from 'tabulator-tables'; //import Tabulator library
import type { ColumnDefinition, CellComponent, } from "tabulator-tables";
import { getPropertyQuickLinks, getOwnerQuickLinks, createQuickLinksList } from "@/helpers/quicklinks"
import type { Owners, QuickLinkItem, ParcelAddressInfo } from "@/helpers/interface/candidates";
import { createTabulator, destroyTabulator } from "@/helpers/true-tabulator"
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import { ucwords } from "@/helpers/common";
import { useAPI } from "@/helpers/services/api"

const api = useAPI()
const store = useCandidateDetailsStore();
const owners = computed((): Owners[] => store.getOwners)


let propertyQuickLinks: QuickLinkItem[] | null
let ownerTabulator: Tabulator | null
let propertyTabulator: Tabulator | null


const ownerTableColumns = ref<ColumnDefinition[]>([
	{
		title: "Owner",
		field: "full_name",
		width: "60%",
		visible: true,
		headerSort: true,
		formatter: function (cell: CellComponent) {
			const owner = cell.getData() as Owners
			return `<div class="row list-row">
                    <div class="col-3">Full name: </div> <div class="col-9 text-truncate">${ucwords(owner.full_name)}</div>
                    <div class="col-3">Birth date: </div> <div class="col-9 text-truncate">${owner.birth_date}</div>
                    <div class="col-3">City: </div> <div class="col-9 text-truncate">${owner.city}</div>
                    <div class="col-3">State: </div> <div class="col-9 text-truncate">${owner.state}</div>
                </div>`;
		}
	},
	{
		title: "Quick Links",
		field: "full_name",
		width: "40%",
		visible: true,
		headerSort: true,
		formatter: function (cell: CellComponent) {
			const owner: Owners = cell.getData() as Owners
			const links = getOwnerQuickLinks(owner)
			return createQuickLinksList(links)
		}
	}
])


const propertyTabulatorTableColumns = ref<ColumnDefinition[]>([
	{
		title: "Property Quick Links",
		field: "items",
		width: "100%",
		visible: true,
		headerSort: false,
		formatter: function () {
			if (!propertyQuickLinks || !propertyQuickLinks.length) {
				return "<p>No data for this address</p>"
			}
			return createQuickLinksList(propertyQuickLinks)
		}
	}
])


const loadOwnerTabulator = async (data: Owners[] | undefined) => {
	ownerTabulator = await createTabulator("#owner-quick-links", {
		data: data,
		columns: ownerTableColumns.value,
		initialSort: [
			{ column: "first_name", dir: "desc" },
		],
		pagination: false,
		placeholder: "No records found.",
	})
}

const loadPropertyTabulator = async () => {
	propertyQuickLinks = await fetchPropertyQuickLinks()
	propertyTabulator = await createTabulator("#property-quick-links", {
		data: [{ "placeholder": true }],
		columns: propertyTabulatorTableColumns.value,
		pagination: false,
		placeholder: "No records found.",
	})
}


const fetchPropertyQuickLinks = async () => {
	if (!store.situsInfo?.tru_id)
		return []

	const { data } = await api.get(`taxroll/address?tru_id=${store.situsInfo.tru_id}`)
	if (!data)
		return []

	return getPropertyQuickLinks(data as ParcelAddressInfo)
}


onMounted(() => {
	loadOwnerTabulator(owners.value)
	loadPropertyTabulator()
})

onUnmounted(() => {
	destroyTabulator(ownerTabulator)
	destroyTabulator(propertyTabulator)
})

watch(() => owners.value, async (data) => {
	await loadOwnerTabulator(data)
})
</script>
