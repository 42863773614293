<template>
    <div id="parcel">
        <table class="table">
            <tbody v-if="!fetchParcelLoading">
                <tr v-for="item in parcelData" :key="item.label">
                    <td class="label">{{ item.label }}</td>
                    <td class="value">{{ item.value }}</td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="i in 6" :key="i">
                    <td>
                        <Skeleton height="1.2rem" width="120px"></Skeleton>
                    </td>
                    <td>
                        <Skeleton height="1.2rem" width="250px"></Skeleton>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup lang="ts">
import type { AxiosError } from "axios"
import { onMounted, ref } from "vue";
import { useAPI } from "@/helpers/services/api"
import Skeleton from "primevue/skeleton"
import { getApiErrorMessage, stringifyPrimitive } from "@/helpers/common";
import { toast } from "@/helpers/toast";


interface DataEntity {
    label: string
    value: string
}

const props = defineProps({
    tru_id: {
        type: String,
        required: true
    }
})

const api = useAPI()


const fetchParcelLoading = ref(true)
const parcelData = ref<DataEntity[] | null>(null)


const loadFailureHandler = (error: any) => {
    const message = getApiErrorMessage(error)
    toast.error(message)
}

const transformData = (rawData: {} | any) : DataEntity[] =>  {
    const ignoreKeys = ["tru_id"]

    const targetKeys = Object.keys(rawData).filter(key => {
        return (!ignoreKeys.includes(key)) && (rawData[key] !== null)
    })

    targetKeys.sort((a,b) => a.toLowerCase().localeCompare(b.toLowerCase()))

    return targetKeys.map(key => {
        return {label: key, value: stringifyPrimitive(rawData[key])}
    })
}


const fetchParcel = async () => {
    const tru_id = props.tru_id
    fetchParcelLoading.value = true

    try {
        const { data } = await api.get(`applications/${tru_id}/parcel`)
        parcelData.value = transformData(data)
    } catch (error: unknown) {
        const err = error as AxiosError
        loadFailureHandler(err)
    }

    fetchParcelLoading.value = false
}

onMounted(() => fetchParcel())
</script>
