
export enum OwnerType {
    Single = "single",
    Married = "married",
    Other = "other",
    Blank = ""
}

export enum ActiveDutyDocumentationType {
    MilitaryID = "Military ID",
    UtilityBill = "Utility Bill"
}

export interface AddressFieldsModel {
    address: string;
    unitNumber: string;
    city: string;
    state: string;
    postalCode: string;
    county?: string;
}

export interface ApplicationForm {
    appraisal_district_account_number: string;
    filing_late_application: boolean | null;
    start_year_of_exemption: number | null;
    exemption_general: boolean;
    exemption_disabled_person: boolean;
    exemption_age_65_plus: boolean;
    exemption_disabled_veteran: boolean;
    is_veteran_disability_permanent: boolean | null,
    exemption_surviving_spouse_armed_services: boolean;
    exemption_surviving_spouse_first_responder: boolean;
    exemption_donated_residence: boolean;
    percent_disability_rating: number | null;
    living_on_property: boolean | null;
    co_op_housing: boolean | null;
    co_op_housing_name: string | null;
    receiving_exemption_previous_residence: boolean | null;
    transferring_exemption_previous_residence: boolean | null;
    transferring_tax_limitation: boolean | null;
    previous_residence_address_line_1: string | null;
    previous_residence_unit_number: string | null;
    previous_residence_city: string | null;
    previous_residence_state: string | null;
    previous_residence_postal_code: string | null;
    previous_residence_county: string | null;
    property_owner_type_1: OwnerType;
    property_owner_name_1: string;
    property_owner_birthdate_1: Date | null;
    property_owner_id_number_1: string;
    property_owner_percent_ownership_interest_1: number | null;
    property_owner_email_1: string;
    property_owner_phone_1: string;
    property_owner_name_2: string | null;
    property_owner_birthdate_2: Date | null;
    property_owner_id_number_2: string | null;
    property_owner_percent_ownership_interest_2: number | null;
    property_owner_email_2: string | null;
    property_owner_phone_2: string | null;
    deceased_spouse_birthdate: Date | null;
    deceased_spouse_name: string | null;
    affirm_id_property_address_match: boolean | null;
    applicant_mailing_address_different: boolean | null;
    property_date_acquired: Date | null;
    property_date_occupied: Date | null;
    property_address_line_1: string;
    property_unit_number: string | null;
    property_city: string;
    property_state: string;
    property_postal_code: string;
    property_legal_description: string;
    applicant_mail_line_1: string | null;
    applicant_mail_unit_number: string | null;
    applicant_mail_city: string | null;
    applicant_mail_state: string | null;
    applicant_mail_postal_code: string | null;
    applicant_identified_on_deed: boolean | null;
    court_filing_number: string | null;
    is_heir_property: boolean | null;
    heir_property_owners_occupy_property: boolean | null;
    property_manufactured_home: boolean | null;
    manufactured_home_make: string;
    manufactured_home_model: string;
    manufactured_home_id: string;
    property_produces_income: boolean | null;
    percent_producing_income: string;
    property_acres_occupied: number | null;
    waive_reason_resident_of_facility: boolean;
    facility_name_address: string; // | null
    waive_reason_participator_address_confidential_program: boolean;
    waive_reason_active_duty: boolean;
    active_duty_documentation_type: string | null;
    waive_reason_special_drivers_license: boolean;
    additional_info: string;
    other_owned_properties: string;
    authorized_print_name: string;
    title_authorization: string;
    signature_of_applicant: string;
}

export interface NamForm {
    id: string;
    customer_id: string;
    fips_code: string;
    name: string;
    state: string;
    api_key: string;
}
