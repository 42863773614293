<template>
    <!-- session monitor --><span></span>
</template>

<script setup lang="ts">
import { useAuth0 } from "@auth0/auth0-vue"
import { onMounted, onUnmounted } from "vue";
import router from "@/router";
import { useProfile } from "@/stores/profile";
import { StorageKeyEnum, storageManager } from "@/helpers/storageManager"

const storeProfile = useProfile();
const { idTokenClaims, isAuthenticated } = useAuth0()
const debugMode = false

let intervalId = 0
let lastExpiration = 0


const startSessionExpirationMonitor = () => {
    debugInfo("Start session monitor")
    const timeout = 60000
    intervalId = window.setInterval(checkSessionExpiration, timeout);
};

const checkSessionExpiration = () => {
    const expiration = getExpiration()
    const currentTime = getCurrentTime()

    if (expiration !== lastExpiration) {
        debugInfo(`Expiration changed to ${expiration}`)
        lastExpiration = expiration
    }

    debugInfo(`Checking session, current ${currentTime}, expiration ${expiration}`)

    if (currentTime >= expiration) {
        stopMonitor()
        storageManager.removeItem(StorageKeyEnum.SessionExpiration)
        router.push({ name: "logout" })
    }
};


const getCurrentTime = () => {
    const start: any = new Date("1970-1-1");
    const now: any = new Date();
    return ~~((now - start) / 1000);
};


const getExpiration = () => {
    const tokenExpiration = idTokenClaims.value?.exp || 0
    let storedExpiration = ~~(storageManager.getItem(StorageKeyEnum.SessionExpiration) || 0)

    if (!storedExpiration || (tokenExpiration > storedExpiration)) {
        storageManager.setItem(StorageKeyEnum.SessionExpiration, tokenExpiration.toString())
        storedExpiration = tokenExpiration

        const displayDate = new Date(tokenExpiration * 1000)
        debugInfo(`Updating session expiration to ${storedExpiration}:\n\t\t${displayDate}`)
    }
    return storedExpiration
};


const debugInfo = (value: string) => {
    debugMode && console.log(value)
};


const onDocumentVisibilityChange = () => {
    debugInfo("Document Visibility Changed")
    checkSessionExpiration()
};


const onWindowFocus = () => {
    debugInfo("Window Focus")
    checkSessionExpiration()
};


const stopMonitor = () => {
    if (intervalId) {
        debugInfo("Stopping session monitor")
        clearInterval(intervalId)
    } else {
        debugInfo("Monitor not running")
    }
};


const setupEvents = () => {
    window.removeEventListener("focus", onWindowFocus);
    window.addEventListener("focus", onWindowFocus);

    document.removeEventListener("visibilitychange", onDocumentVisibilityChange);
    document.addEventListener("visibilitychange", onDocumentVisibilityChange);
};


onMounted(async () => {
    if (isAuthenticated.value) {
        await storeProfile.fetchCustomerAccess()
        debugInfo("Session monitor mounted")
        startSessionExpirationMonitor()
        setupEvents()
    }
});


onUnmounted(() => {
    // clear interval when navigating elsewhere
    stopMonitor()
});
</script>
