<template>
    <div class="actions-description">
        You may add and delete labels associated with this record using the list below.
    </div>
    <div class="actions-form">
        <form @submit.prevent="submitLabelForm()">
            <table class="table">
                <thead class="table-secondary">
                    <tr>
                        <th width="40%">Label</th>
                        <th width="30%">Added On</th>
                        <th width="30%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <select class="cbFormTextField cbFormTextField-sm" v-model="formLabelId" required
                                :disabled="formLoading" id="labels">
                                <option value="" selected>-- Select --</option>
                                <option v-for="label in labelsDropdownAvailable" :value="label.id" :key="label.id">
                                    {{ label.label_name }}
                                </option>
                            </select>
                        </td>
                        <td></td>
                        <td class="d-flex">
                            <loading-icon v-if="formLoading" />
                            <button class="searchButton" type="submit" :disabled="formLoading">Submit</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>

        <div class="mt-2" id="candidate-label-list"></div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, onUnmounted } from "vue"
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import type { Label, CandidateFormLabels } from "@/helpers/interface/candidates"
import { useAPI } from "@/helpers/services/api";
import { getApiErrorMessage } from "@/helpers/common";
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import { toast } from "@/helpers/toast";
import { useRoute } from "vue-router"
import type { AxiosError } from "axios";
import dayjs from "dayjs"
import type { CandidateLabelDeleteFunc } from "@/helpers/interface/admin-page";
import { createTabulator, destroyTabulator } from "@/helpers/true-tabulator";
import type { ColumnDefinition, CellComponent } from "tabulator-tables";
import type { TabulatorFull as Tabulator } from 'tabulator-tables'

const route = useRoute()
const api = useAPI()
const storeCandidateDetails = useCandidateDetailsStore();

const fetchLabelsDropdownListLoading = ref(false)
const fetchLabelsListLoading = ref(false)
const removeLabelLoading = ref(false)
const formLoading = ref(false)
const tru_id = route?.params?.tru_id as string

const labelsDropdown = ref<Label[]>([])
const labelsDropdownAvailable = ref<Label[]>([])
const currentQueue = computed(() => (storeCandidateDetails.situsInfo.queue));
const labelsList = ref<CandidateFormLabels[]>([])
const removeLabelElementFunc = ref<CandidateLabelDeleteFunc>({
    removeElement: null,
    loadingElement: null,
    labelID: null,
    labelName: ""
})
const formLabelId = ref<string>("")

let tabulator: Tabulator | null;
const tableColumns = ref<ColumnDefinition[]>([
    {
        title: "Label Name",
        field: "label_name",
        width: "40vh",
        headerSort: false
    },
    {
        title: "Created On",
        field: "created_at",
        width: "30vh",
        headerSort: false,
        formatter: function (cell: CellComponent) {
            const data = cell.getValue()
            return (data) ? dayjs(data).format("M/D/YYYY") : ""
        }
    },
    {
        title: "&nbsp;",
        field: "updated_at",
        width: "25vh",
        headerSort: false,
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateFormLabels
            const actionParent = document.createElement("div")
            const labelID = data.label_id
            const labelName = data.label_name

            const removeButton = document.createElement("a")
            removeButton.innerHTML = "Delete"
            removeButton.classList.add("searchButton", "removeButton", "text-white")
            removeButton.setAttribute("id", `remove-btn-${labelID}`)
            removeButton.href = "javascript:;"
            removeButton.addEventListener("click", () => {
                const id = `remove-btn-loading-${labelID}`

                const removeButtonLoading = document.createElement("div")
                removeButtonLoading.classList.add("remove-btn")
                removeButtonLoading.classList.add("d-inline")
                removeButtonLoading.classList.add("d-none")
                removeButtonLoading.setAttribute("id", id)
                removeButtonLoading.innerHTML = '<span class="fa fa-spinner fa-spin"></span>'

                if (!document.getElementById(id)) actionParent.append(removeButtonLoading)

                removeLabelElementFunc.value = {
                    removeElement: removeButton,
                    loadingElement: removeButtonLoading,
                    labelID: labelID,
                    labelName: labelName
                }

                detachLabelFromCandidate(removeLabelElementFunc.value)

            })
            actionParent.append(removeButton)
            return actionParent
        },
    }
])

watch(() => currentQueue.value, async () => {
    await fetchLabelsList()
    await fetchLabelsDropdownList()
    await hideAttachedLabels()
})

watch(() => fetchLabelsListLoading.value, async (isLoading) => {
    buildTabulator(isLoading)
})

const buildTabulator = async (isLoading: boolean = false) => {
    tabulator = await createTabulator("#candidate-label-list", {
        data: labelsList.value,
        placeholder: "No Labels associated to this record yet. Add them below.",
        columns: tableColumns.value,
        initialSort: [
            { column: "label_name", dir: "asc" },
        ]
    }, isLoading)
}

const loadFailureHandler = (error: AxiosError) => {
    const message = getApiErrorMessage(error)
    toast.error(message)
}

let fetchLabelsDropdownList = async () => {
    fetchLabelsDropdownListLoading.value = true;
    try {
        const response = await api.get('/labels/?candidates=true');
        const items: unknown = response.data ? response.data : []
        labelsDropdown.value = items as Label[]
    }
    catch (error) {
        labelsDropdown.value = [];
    }
    finally {
        fetchLabelsDropdownListLoading.value = false;
    }

    labelsDropdownAvailable.value = labelsDropdown.value
}

let fetchLabelsList = async () => {
    fetchLabelsListLoading.value = true
    await api.get(`/taxroll/labels/${tru_id}/`)
        ?.then(response => {
            labelsList.value = response.data.data
        })
        ?.catch(() => {
            labelsList.value = []
        })

    fetchLabelsListLoading.value = false;
}

let hideAttachedLabels = async () => {
    const labelIds = labelsList.value.map(item => item.label_id)
    labelsDropdownAvailable.value = labelsDropdown.value.filter((item) => !labelIds.includes(item.id))
}

let detachLabelFromCandidate = async (removeLabelElementFunc: CandidateLabelDeleteFunc) => {
    removeLabelLoading.value = true;
    removeLabelElementFunc.removeElement?.classList.add("d-none")
    removeLabelElementFunc.loadingElement?.classList.remove("d-none")

    const labelID = removeLabelElementFunc.labelID
    const labelName = removeLabelElementFunc.labelName

    try {
        await api.patch(`/taxroll/remove_label/${tru_id}/`, { "label_id": labelID });
        toast.success(`Label "${labelName}" removed from the candidate`)
        await fetchLabelsList()
        await hideAttachedLabels()
        await storeCandidateDetails.fetchHistory(tru_id, loadFailureHandler)
    }
    catch (error: any) {
        toast.error(getApiErrorMessage(error))
    }

    removeLabelLoading.value = false;
    removeLabelElementFunc.removeElement?.remove()
    removeLabelElementFunc.loadingElement?.classList.remove("d-none")
}

// Submit Form
let submitLabelForm = async () => {
    formLoading.value = true
    try {
        await api.post(`/taxroll/associate_label/${tru_id}/`, { "label_id": formLabelId.value })
            .then(async () => {
                await fetchLabelsList()
                await hideAttachedLabels()
                await storeCandidateDetails.fetchHistory(tru_id, loadFailureHandler)
                formLabelId.value = ""
                toast.success("Your submission was successful")
            })
    }
    catch (error: any) {
        toast.error(getApiErrorMessage(error))
    }

    formLoading.value = false;
}

onUnmounted(() => destroyTabulator(tabulator))
</script>

<style>
@import "@/assets/candidate-details.css";
</style>
