<template>
    <div>
        <label class="config-header">Change Customer</label>
        <div class="actions-description">
            This page provides you with the option to modify the customer account you are currently accessing. With this
            feature, you can easily switch to a different customer account as needed.
        </div>
        <form @submit.prevent="changeCustomerAccess()" class="my-3" v-if="availableCustomersList.data.length !== 0">
            <div class="row">
                <div class="col-sm-2">
                    <label for="customer-search-select">Customer Name</label>
                </div>
                <div class="col-sm-3">
                    <span id="v-select-options" :options="dropDownItems.map(r => r.id).join(',') || null"></span>
                    <v-select v-model="customer_id" :options="dropDownItems" :clearable="false" append-to-body
                        :calculate-position="setDropdownPosition" @option:selected="customerChangeHandler"
                        input-id="customer-search-select" :reduce="customerSelectReduce"></v-select>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="offset-sm-2 col-sm-4 d-flex">
                    <button class="searchButton cbFormTextField" type="submit"
                        :disabled="availableCustomersList.data.length === 0 || formLoading">
                        Update
                    </button>
                    <loading-icon v-if="formLoading" />
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue"
import { useAdminDetails } from "@/stores/adminDetails";
import { useProfile } from "@/stores/profile";
import { toast } from "@/helpers/toast";
import { useAPI } from "@/helpers/services/api";
import { getApiErrorMessage } from "@/helpers/common"
import type { CustomerList } from '@/helpers/interface/admin-page';
import type { VSelectListItem } from '@/helpers/interface/general';
import LoadingIcon from "@/components/Shared/LoadingIcon.vue"
import type { AxiosError } from "axios"

const api = useAPI()
const storeAdminDetails = useAdminDetails();
const storeProfile = useProfile();

const formLoading = ref(false)
const availableCustomersList = computed((): CustomerList => {
    return storeAdminDetails.getCustomerList
});

const currentCustomerAccess = computed(() => storeProfile.getCustomerAccess)

const customer_id = ref<string>(currentCustomerAccess.value?.customer_id as string)

const loadFailureHandler = (error: AxiosError) => {
    const message = getApiErrorMessage(error, { "featureName": "Customer List" })
    toast.error(message)
}

onMounted(() => {
    storeAdminDetails.fetchCustomersList(loadFailureHandler)
})

const customerSelectReduce = (item: VSelectListItem) => {
    return item.id  // this is the customer_id
}

const customerChangeHandler = (item: VSelectListItem) => {
    customer_id.value = item.tag as string
}

const setDropdownPosition = (dropdownList: HTMLUListElement) => {
    const input = document.getElementById("customer-search-select");

    let node = input?.parentElement
    while (node && !node.matches(".v-select")) {
        node = node.parentElement
    }
    if (!node)
        return;

    const rect = node.getBoundingClientRect()

    dropdownList.style.left = (rect.left + 3) + "px"
    dropdownList.style.top = (rect.top + rect.height) + "px"
    dropdownList.style.width = (rect.width + 3) + "px"
}

const dropDownItems = computed(() => {
    return availableCustomersList.value.data
        .filter(c => !!c.customer_id)
        .map(c => {
            return { "id": c.customer_id, "label": c.name, "tag": c.customer_id }
        })

});

let changeCustomerAccess = async () => {
    formLoading.value = true
    // Submit Request
    try {
        await api.patch(`/users/me`, { customer_id: customer_id.value });
        toast.success(`Your update has been saved`)

        // Delay 1.5s
        setTimeout(() => window.location.reload(), 1000)
    }
    catch (error: any) {
        toast.error(getApiErrorMessage(error))
    }
    finally {
        formLoading.value = false;
    }
}
</script>