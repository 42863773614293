<template>
    <section>
        <h2 class="text-center">{{ props.title }}</h2>
        <h3 v-if="!!props.subtitle" class="text-center">{{ props.subtitle }}</h3>
        <div class="section-content">
            <slot></slot>
        </div>
    </section>
</template>

<script setup lang="ts">

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    subtitle: {
        type: String,
        required: false
    }
})
</script>
