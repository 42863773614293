<template>
	<div class="card-cl-actions" style="height: 500px; width: 100%">
		<div class="card-body">
			<ul class="nav nav-pills" id="myTab" role="tablist">
				<li class="nav-item" role="presentation">
					<a class="nav-link active" id="flags-tab" data-bs-toggle="tab" data-bs-target="#flags-tab-pane"
						type="button" role="tab" aria-controls="flags-tab-pane" aria-selected="true">+Flags</a>
				</li>
				<li class="nav-item" role="presentation">
					<a class="nav-link" id="docs-tab" data-bs-toggle="tab" data-bs-target="#docs-tab-pane" type="button"
						role="tab" aria-controls="docs-tab-pane" aria-selected="true">+Docs</a>
				</li>
				<li class="nav-item" role="presentation">
					<a class="nav-link" id="labels-tab" data-bs-toggle="tab" data-bs-target="#labels-tab-pane" type="button"
						role="tab" aria-controls="labels-tab-pane" aria-selected="true">+Labels</a>
				</li>
			</ul>
			<div class="tab-content accordion" id="myTabContent">
				<div class="tab-pane fade show active accordion-item" id="flags-tab-pane" role="tabpanel"
					aria-labelledby="flags-tab" tabindex="0">
					<div id="collapseTwo" class="accordion-collapse collapse show d-lg-block" aria-labelledby="headingTwo"
						data-bs-parent="#myTabContent">
						<div class="accordion-body m-1">
							<CandidateFlagForm />
						</div>
					</div>
				</div>
				<div class="tab-pane fade accordion-item" id="labels-tab-pane" role="tabpanel" aria-labelledby="labels-tab"
					tabindex="0">
					<div id="collapseThree" class="accordion-collapse collapse show d-lg-block"
						aria-labelledby="headingThree" data-bs-parent="#myTabContent">
						<div class="accordion-body m-1">
							<CandidateLabelsForm />
						</div>
					</div>
				</div>

				<div class="tab-pane fade accordion-item" id="docs-tab-pane" role="tabpanel" aria-labelledby="docs-tab"
					tabindex="0">
					<div id="collapseThree" class="accordion-collapse collapse d-lg-block" aria-labelledby="headingThree"
						data-bs-parent="#myTabContent">
						<div class="accordion-body m-1">
							<CandidateDocumentForm />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue"
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import type {
	CandidateFormUpdate,
} from "@/helpers/interface/candidates"
import CandidateFlagForm from "@/components/Shared/FormAction/CandidateFlagForm.vue"
import CandidateLabelsForm from "@/components/Shared/FormAction/CandidateLabelsForm.vue"
import CandidateDocumentForm from "@/components/Shared/FormAction/CandidateDocumentForm.vue"


const store = useCandidateDetailsStore();
const formUpdate = ref<CandidateFormUpdate>({
	queue: "",
	add_note: false
})

onMounted(() => {
	let queue = computed(() => (store.situsInfo.queue));
	formUpdate.value.queue = queue.value
})

const first_unqualified_year_options: number[] = []
for (let index = 2009; index <= (new Date().getFullYear() + 1); index++) {
	first_unqualified_year_options.push(index)
}

</script>

<style>
@import "@/assets/candidate-details.css";

.actions-description {
	background: #004eff1f;
	padding: 5px;
	font-size: 13px;
	margin-bottom: 5px;
}

.accordion-body {
	padding: 5px !important;
}

textarea {
	width: 100%;
	height: 100px;
}

.textwrapper {
	border: 1px solid var(--main-input-border-color);
	margin: 5px 0;
	padding: 3px;
}
</style>
