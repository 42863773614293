<template>
    <div class="mt-2">
        <div class="alert-product-pitch">
            <p>
                Application Vetter is TrueRoll's web-based solution that empowers jurisdictions to make eligibility
                decisions on new
                residential property tax exemption applications at scale.
            </p>

            <p>These are just a few of the ways Application Vetter can help your office:</p>
            <ul>
                <li>Automate processing and vetting new applications and application backlogs</li>
                <li>Integration with your CAMA and vetting digital (online) applications</li>
                <li>Improving application workflows and saving your staff time</li>
            </ul>

            <p>If you would like more information on how Application Vetter can help your jurisdiction, please reach out to
                our
                Customer
                Success Team at <a href="mailto:csm@trueroll.io">csm@trueroll.io</a>!
            </p>
        </div>
    </div>
</template>
<style>
.alert-product-pitch {
    max-width: 900px;
    margin: auto;
    margin-bottom: 20px;
    background-color: #fff;
    color: var(--general-text-color);
    border-width: 1px;
    border-color: #dedede;
    border-radius: 0;
    background: url('/images/salesPitchBG.jpg');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    padding: 50px 200px;
    line-height: 1.4;
}
</style>