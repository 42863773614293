<template>
    <TheHeader />
    <div v-if="!loadingState">
        <div v-if="hasCustomerAccessToApplicationVetter === true">
            <div v-if="hasPermissionToUpdateApplicationVetter">
                <SidebarNavigation :menus="sidebarMenus" :collapsed="isCollapsed" :activeMenu="activeMenu"
                    :key="activeMenu" @menuSelected="handleMenuSelected" @collapseChanged="handleCollapsed" />
                <div :class="['sidebar-content-wrapper', { 'sidebar-collapsed': isCollapsed }]">
                    <div class="sidebar-content">
                        <Card v-if="activeMenu === 'inbox'">
                            <template #content>
                                <ApplicationVetterList @updateStatus="handleUpdateStatus"
                                    @qsArchive="handleQuickSearchArchive" :queue="['pending']" />
                            </template>
                        </Card>
                        <Card v-if="activeMenu === 'snooze'">
                            <template #content>
                                <ApplicationVetterList @updateStatus="handleUpdateStatus"
                                    @qsArchive="handleQuickSearchArchive" :queue="['snooze']" />
                            </template>
                        </Card>
                        <Card v-if="activeMenu === 'archive'">
                            <template #content>
                                <ApplicationVetterList @updateStatus="handleUpdateStatus"
                                    @qsArchive="handleQuickSearchArchive" :queue="['approved', 'denied']" />
                            </template>
                        </Card>
                        <Card v-if="activeMenu === 'activity'">
                            <template #content>
                                <ApplicationVetterDashboard />
                            </template>
                        </Card>
                        <Card v-if="activeMenu === 'assigned_to_me'">
                            <template #content>
                                <ApplicationVetterList @updateStatus="handleUpdateStatus"
                                    @qsArchive="handleQuickSearchArchive" :queue="['assigned_to_me']" />
                            </template>
                        </Card>
                        <Card v-if="activeMenu === 'application_upload'">
                            <template #content>
                                <ModalDialog title="Select Application Type" :close="() => handleMenuSelected('inbox')">
                                    <div class="action-toolbar-container" v-if="currentCustomer">
                                        <Button :disabled="!currentCustomer.allow_paper_applications" type="button"
                                            class="searchButton cbFormTextField mt-1"
                                            @click="selfServeDataRequest('Paper Applications', currentCustomer.customer_id)">
                                            <span class="fa-solid fa-file-pdf" aria-hidden="true"></span>
                                            Paper
                                        </Button>
                                        <Button :disabled="!currentCustomer.allow_digital_applications" type="button"
                                            class="searchButton cbFormTextField mt-1"
                                            @click="selfServeDataRequest('Digital Applications', currentCustomer.customer_id)">
                                            <span class="fa-solid fa-table-list" aria-hidden="true"></span>
                                            Digital
                                        </Button>
                                    </div>
                                </ModalDialog>
                            </template>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="hasCustomerAccessToApplicationVetter === false">
            <br>
            <AppVetSalesPage />
        </div>
        <div v-else class="d-block text-center m-3">
            <loading-icon class="d-inline" /> Hang tight! We're fetching your data as quickly as possible.
        </div>
    </div>
    <AppMonitor />
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue"
import TheHeader from "@/components/TheHeader.vue";
import AppMonitor from "@/components/AppMonitor.vue"
import ApplicationVetterList from "@/components/ApplicationVetter/ApplicationVetterList.vue";
import ApplicationVetterDashboard from "@/components/ApplicationVetter/ApplicationVetterDashboard.vue";
import SidebarNavigation from "@/components/Shared/SidebarNavigation.vue"
import { validateUserPermission } from "@/helpers/common";
import { useProfile } from "@/stores/profile"
import AppVetSalesPage from "@/components/Shared/AppVetSalesPage.vue";
import Card from 'primevue/card';
import { useAPI } from "@/helpers/services/api"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import type { SidebarNavigationMenu } from "@/helpers/interface/candidates";
import ModalDialog from "@/components/Shared/ModalDialog.vue";
import Button from "primevue/button";
import type { NewDataRequest } from "@/helpers/interface/trueload";
import router from "@/router"
import dayjs from "dayjs";


const api = useAPI()
const storeProfile = useProfile();
const currentCustomer = computed(() => storeProfile.getCustomerAccess)

const activeMenu = ref('inbox')
const sidebarMenus = ref<SidebarNavigationMenu[]>([
    { label: "Inbox", iconClass: "fas fa-inbox", id: "inbox" },
    { label: "Snooze", iconClass: "fas fa-clock", id: "snooze", badge: 0 },
    { label: "Archive", iconClass: "fas fa-archive", id: "archive" },
    { label: "Activity", iconClass: "fas fa-chart-line", id: "activity" },
    { label: "Assigned To Me", iconClass: "fas fa-user-tag", id: "assigned_to_me" },
    { label: "Submit Applications", iconClass: "fa-solid fa-cloud-arrow-up", id: "application_upload" }
])

const isCollapsed = ref(false)
const handleMenuSelected = (menuId: string) => activeMenu.value = menuId
const handleQuickSearchArchive = () => activeMenu.value = "archive"
const handleCollapsed = (collapsed: boolean) => isCollapsed.value = collapsed
const loadingState = computed(() => storeProfile.loadingState)
const hasPermissionToUpdateApplicationVetter = computed(() => validateUserPermission("update", "applications"))
const hasCustomerAccessToApplicationVetter = computed(() => storeProfile.getCustomerAccess?.application_vetter || validateUserPermission("read", "customers"))
const handleUpdateStatus = (bulkUpdate: { prevStatus: string, status: string }) => updateAllBadges(bulkUpdate.status.replace(" ", "_"), bulkUpdate.prevStatus, false)

const selfServeDataRequest = async (request_type: string, customer_id: string) => {
    const dataRequest: NewDataRequest = {
        customer_id: customer_id,
        title: "Self-Service Application Upload",
        recipient_email: "",
        request_type: request_type,
        description: "Please submit all applications below",
        items: [{ "id": "applications", "title": "Applications" }],
        osm_emails: "onboarding@trueroll.io"
    }
    const resp = await api.post(`data-requests/`, dataRequest)
    await router.push({ name: 'dataDrop', params: { data_drop_id: resp.data.id } })
}

const updateAllBadges = (status: string, prevStatus: string, all: boolean = true) => {
    if ([...prevStatus, status].includes("snooze") || all) updateExpiredSnoozeCount()
}

const updateExpiredSnoozeCount = async () => {
    const today = dayjs().format('YYYY-MM-DD')
    const filters = [
        { field: "application_status", type: "in", value: ["snooze"] },
        { field: "snooze_until", type: "<=", value: today }
    ]

    const data = await api.post("/applications/count", filters)
    const count = data?.data?.count || 0
    const menuItem = sidebarMenus.value.find(menu => menu.id === "snooze")
    if (menuItem) menuItem.badge = count
}

onMounted(async () => {
    await storeProfile.fetchUserPermissions()
    updateAllBadges("", "", true)
})
</script>
