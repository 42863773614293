<template>
	<div style="background-color: #F9F9F9;">
		<TheHeader />
		<div class="row" style="">
			<div class="col-sm-4 col-md-4">
				<SitusInfo />
			</div>
			<div class="col-sm-5 col-md-5">
				<div class="card-cl-flags" style="height: 500px; width: 100%">
					<div class="card-body">
						<Flags v-if="!dataLoading" />
						<Notes v-if="!dataLoading" />
						<QuickLinks v-if="!dataLoading && hasPermissionToViewQuickLinks" />
					</div>
				</div>
			</div>
			<div class="col-sm-3 col-md-3">
				<Map :mapSrc="situsMapAddress" />
			</div>
		</div>
		<div class="row mx-2 my-2">
			<div class="col-sm-4 col-md-4">
				<Actions />
			</div>
			<div class="col-sm-8 col-md-8">
				<History v-if="!dataLoading" />
			</div>
		</div>
		<TheFooter />
	</div>
	<AppMonitor />
</template>

<script setup lang="ts">
import router from "@/router"
import { useRoute } from "vue-router";
import { useProfile } from "@/stores/profile";
import type { AxiosError } from "axios"
import { toast } from "@/helpers/toast";
import { onMounted, computed, watch, ref } from "vue"
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import AppMonitor from "@/components/AppMonitor.vue"
import Map from "@/components/Shared/DetailsMap.vue";
import { getApiErrorMessage, validateUserPermission } from "@/helpers/common"
import { logPageView } from "@/helpers/insights";
import Flags from "@/components/Shared/DetailsFlags.vue";
import Notes from "@/components/Shared/DetailsNotes.vue";
import QuickLinks from "@/components/Shared/DetailsQuickLinks.vue";
import History from "@/components/Shared/DetailsHistory.vue";
import SitusInfo from "@/components/Shared/DetailsSitusInfo.vue";
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import Actions from "@/components/Candidates/CandidateDetailsActionsForm.vue";

const route = useRoute()
const store = useCandidateDetailsStore();
const storeProfile = useProfile()
const tru_id = route?.params?.tru_id as string
const situsMapAddress = computed(() => store.situsInfo.situs_full_taxroll)
const dataLoading = ref(false)
const situsInfo = computed(() => (store.situsInfo))
const hasPermissionToViewQuickLinks = computed(() => validateUserPermission("read", "investigation_quick_links"))

watch(situsInfo, (situsInfo) => {
	if (!situsInfo.tru_id) return
	if (!situsInfo.queue) router.push({ name: "taxrollDetails", params: { tru_id } })
})

const loadFailureHandler = (error: AxiosError) => {
	const message = getApiErrorMessage(error, { "featureName": "Candidate" })
	toast.error(message)
}

onMounted(async () => {
	dataLoading.value = true
	await storeProfile.fetchUserPermissions()

	await Promise.all([
		store.fetchFlags(tru_id, loadFailureHandler),
		store.fetchNotes(tru_id, loadFailureHandler),
		hasPermissionToViewQuickLinks.value && store.fetchOwners(tru_id, loadFailureHandler),
		store.fetchSitusInfo(tru_id, loadFailureHandler),
		store.fetchHistory(tru_id, loadFailureHandler)
	]);
	dataLoading.value = false
})

logPageView({ "pageName": "candidate-details" })
</script>

<style>
@import "@/assets/candidate-details.css";
</style>