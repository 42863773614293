<template>
    <div class="actions-description">
        Review: As an optional service, TrueRoll offers full service investigation of your
        Candidates by a trained homestead subject matter expert. This form is for those individuals
        to file a report and recommendation for this Candidate.
    </div>
    <div class="actions-form">
        <form @submit.prevent="submitIRForm()">
            <div class="row p-2">
                <div class="col-12">
                    Recommendation <span class="required">*</span>
                    <br>
                    <ReportRecommendationDropdown v-model="formReport.recommendation" :customClass="'cbFormTextField cbFormTextField-xl'" />
                </div>
            </div>
            <template v-if="formReport.recommendation">
                <div class="row p-2" v-if="unqualifiedSelected">
                    <div class="col-12">
                        First Unqualified Year <span class="required">*</span>
                        <br>
                        <select class="cbFormTextField cbFormTextField-xl" id="first_unqualified_year"
                            v-model="formReport.first_unqualified_year" required>
                            <option value="" selected>-- Any --</option>
                            <option v-for="f in first_unqualified_year_options" :value="f" :key="f">
                                {{ f }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row p-2" v-if="unqualifiedSelected">
                    <div class="col-12">
                        Primary Violation Reason <span class="required">*</span>
                        <br>
                        <select class="cbFormTextField cbFormTextField-xl" id="reason" v-model="formReport.reason" required>
                            <option value="" selected>-- Any --</option>
                            <option v-for="pv in primaryViolationReasonOptions" :value="pv.value" :key="pv.value">
                                {{ pv.text }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row p-2">
                    <div class="col-12">
                        Summary of Recommendation
                        <br>
                        <RichTextEditor v-model:editorData="formReport.summary_par_1" :fieldID="'summaryPar1LimitExceed'"
                            @exceed-char-limit="handleCharLimitExceed" />
                    </div>
                </div>
                <div class="row p-2" v-if="unqualifiedSelected">
                    <div class="col-12">
                        Owner Whereabouts
                        <br>
                        <RichTextEditor v-model:editorData="formReport.summary_par_2" :fieldID="'summaryPar2LimitExceed'"
                            @exceed-char-limit="handleCharLimitExceed" />
                    </div>
                </div>
                <div class="row p-2" v-if="unqualifiedSelected">
                    <div class="col-12">
                        Situs Resident Whereabouts
                        <br>
                        <RichTextEditor v-model:editorData="formReport.summary_par_3" :fieldID="'summaryPar3LimitExceed'"
                            @exceed-char-limit="handleCharLimitExceed" />
                    </div>
                </div>
                <div class="row p-2">
                    <div class="col-12 d-flex">
                        <button :disabled="formLoading || exceedCharLimit" class="searchButton"
                            type="submit">Submit</button>
                        <loading-icon v-if="formLoading" />
                    </div>
                </div>
            </template>
            
        </form>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue"
import { primaryViolationReasonOptions } from "@/helpers/dropdownOptions"
import { useAPI } from "@/helpers/services/api";
import { getApiErrorMessage, QueueCode } from "@/helpers/common";
import { toast } from "@/helpers/toast";
import { useRoute } from "vue-router"
import type { CandidateFormReport } from "@/helpers/interface/candidates"
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import type { AxiosError } from "axios";
import RichTextEditor from "@/components/Shared/RichTextEditor.vue";
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import ReportRecommendationDropdown from "@/components/Shared/ReportRecommendationDropdown.vue";
import dayjs from "dayjs";


const route = useRoute()
const api = useAPI()
const storeCandidateDetails = useCandidateDetailsStore();

const formLoading = ref(false)
const tru_id = route?.params?.tru_id as string

const summaryPar1LimitExceed = ref(false);
const summaryPar2LimitExceed = ref(false);
const summaryPar3LimitExceed = ref(false);
const exceedCharLimit = computed(() => (summaryPar1LimitExceed.value || summaryPar2LimitExceed.value || summaryPar3LimitExceed.value))
const unqualifiedSelected = computed(() => formReport.value.recommendation == QueueCode.Unqualified)


const handleCharLimitExceed = (exceed: boolean, field: string) => {
    const fieldRefs: any = {
        summaryPar1LimitExceed,
        summaryPar2LimitExceed,
        summaryPar3LimitExceed,
    }

    if (fieldRefs[field] !== undefined) {
        fieldRefs[field].value = exceed;
    }
}

const first_unqualified_year_options: number[] = []
for (let index = 2009; index <= (new Date().getFullYear() + 1); index++) {
    first_unqualified_year_options.push(index)
}

const formReportInitialState = {
    recommendation: "",
    first_unqualified_year: "",
    reason: "",
    summary_par_1: "", // summary of recommendation
    summary_par_2: "", // owner whereabouts
    summary_par_3: "" // situs resident whereabouts
}

const formReport = ref<CandidateFormReport>({
    ...formReportInitialState
})

const loadFailureHandler = (error: AxiosError) => {
    const message = getApiErrorMessage(error)
    toast.error(message)
}

let submitIRForm = async () => {
    const now = dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]')
    formLoading.value = true

    let postData: CandidateFormReport = {
        ...formReport.value,
    };
    if ( !unqualifiedSelected.value ) {
        postData.summary_par_2 = ""
        postData.summary_par_3 = ""
        postData.first_unqualified_year = null
        postData.reason = null
    }
    const time_fields = {
        start_time: now,
        submit_time: now,
    }
    // Submit Request
    try {
        await api.post(`/taxroll/investigation_report/${tru_id}`, {
            ...postData,
            ...time_fields
        });
        toast.success("New Investigation Report Added")
        formReport.value = { ...formReportInitialState }
        await storeCandidateDetails.fetchHistory(tru_id as string, loadFailureHandler)
    }
    catch (error: any) {
        toast.error(getApiErrorMessage(error))
    }
    finally {
        formLoading.value = false;
    }
}

</script>

<style>
@import "@/assets/candidate-details.css";
</style>
