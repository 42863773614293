<template>
	<div class="card-cl" style="height: 500px; width: 100%">
		<div class="card-body">
			<iframe frameborder="0" height="450px" marginheight="0" marginwidth="0" name="mapwindow" scrolling="no"
				:src="`https://maps.google.com/maps?width=100%&amp;height=450&amp;zoom=50&amp;hl=en&amp;q=${props.mapSrc}&amp;ie=UTF8&amp;t=k&amp;z=19&amp;iwloc=B&amp;output=embed`"
				width="100%"></iframe>
		</div>
	</div>
</template>	

<script setup lang="ts">
const props = defineProps({
	mapSrc: String
})
</script>