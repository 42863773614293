<template>
    <div>
        <label class="config-header">Report: Download Unclaimed List</label>
        <div class="row" v-if="!generatingReport">
            <div class="col-3 mt-2">
                <div class="d-flex">
                    <button class="searchButton cbFormTextField-sm" type="submit" @click="generateNewReport"
                        :disabled="generateNewReportLoading">
                        <span class="fa fa-cog" aria-hidden="true"></span>
                        Generate New Report
                    </button>
                </div>
            </div>
        </div>
        <hr v-if="['completed', 'failed'].includes(requestStatus)">
        <div class="row">
            <div class="col-6 mt-2 d-flex">
                <loading-icon v-if="requestRecentJobStatusLoading"></loading-icon>
                <div class="" v-else>
                    <div v-if="['running', 'pending'].includes(requestStatus)">
                        <label class="my-2">
                            A new report request was submitted <i v-if="generateNewReportRequestedDate" role="button"
                                :title="dayjs(generateNewReportRequestedDate).format('YYYY-MM-DD HH:mm:ss')">({{
                                    dayjs(generateNewReportRequestedDate).fromNow()
                                }})</i>.
                            <br>
                            We're in the process of generating the report. Thank you for your understanding
                        </label>
                        <br>
                        <button class="searchButton cbFormTextField mt-2" type="submit" @click="requestRecentJobStatus">
                            <span class="fa fa-refresh" aria-hidden="true"></span>
                            Refresh
                        </button>
                    </div>
                    <div v-if="requestStatus === 'completed' && recentReportDownloadLink">
                        The latest report is available for download:
                        <a :href="recentReportDownloadLink" target="_blank" download="">
                            <span class="fa fa-download" aria-hidden="true"></span>
                            Download Report
                        </a>
                        <label v-if="lastGeneratedReportDate">
                            (Posted <i role="button"
                                :title="dayjs(lastGeneratedReportDate).format('YYYY-MM-DD HH:mm:ss')">{{
                                    dayjs(lastGeneratedReportDate).fromNow()
                                }}</i>)
                        </label>
                    </div>
                    <div v-if="requestStatus === 'failed'">
                        Generation report failed. Please contact the engineering team, and we'll address it. <br>
                        Thank you for your patience.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue"
import { toast } from "@/helpers/toast";
import type { AxiosError } from "axios"
import { useAPI } from "@/helpers/services/api";
import { getApiErrorMessage } from "@/helpers/common"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const api = useAPI()
const generateNewReportLoading = ref<boolean>(false)
const generateNewReportRequestedDate = ref<string>("")
const requestStatus = ref<string>("")
const requestRecentJobStatusLoading = ref<boolean>(false)
const recentReportDownloadLink = ref<string>("")
const lastGeneratedReportDate = ref<string>("")
const generatingReport = computed(() => {
    return ["running", "pending"].includes(requestStatus.value) || requestRecentJobStatusLoading.value
})

const generateNewReport = async () => {
    generateNewReportLoading.value = true

    try {
        await api.post("/taxroll/generate_unclaimed")
        toast.success("Your request has been submitted. Processing may take a few minutes", { duration: 6000 })
        await requestRecentJobStatus()
    } catch (error: unknown) {
        const err = error as AxiosError
        toast.error(getApiErrorMessage(err))
    }

    generateNewReportLoading.value = false;
}

const requestRecentJobStatus = async () => {
    requestRecentJobStatusLoading.value = true

    try {
        const { data } = await api.get("/jobs/?job_type=unclaimed")
        requestStatus.value = data[0]?.status || ""

        if (requestStatus.value === 'completed') {
            await getReportDownloadLink()
        }

        if (["pending", "running"].includes(requestStatus.value)) {
            generateNewReportRequestedDate.value = data[0]?.created_at || ""
        }

    } catch (error: unknown) {
        const err = error as AxiosError
        toast.error(getApiErrorMessage(err))
    }

    requestRecentJobStatusLoading.value = false
}

const getReportDownloadLink = async () => {
    try {
        const { data } = await api.get("/uploads/by_category/unclaimed")
        recentReportDownloadLink.value = data[0]?.download_url || ""
        lastGeneratedReportDate.value = data[0]?.created_at || ""
    } catch (error: unknown) {
        const err = error as AxiosError
        toast.error(getApiErrorMessage(err))
    }
}

onMounted(async () => {
    await requestRecentJobStatus()
})
</script>
