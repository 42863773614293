<template>
  <div></div>
</template>
<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';
import { removeCookie } from '@/helpers/common';
import { storageManager } from "@/helpers/storageManager"

const { logout } = useAuth0();
removeCookie('productFruitsOfLoom')
storageManager.cleanup()
logout({ logoutParams: { returnTo: window.location.origin } });
</script>
