<template>
  <!--  get data_drop_id from the router parameter -->
  <div v-if="isAuthenticated">
    <TheHeader/>
    <markdown-view/>
  </div>
  <div class="container">
    <div v-if="state.dataRequestLoading" class="spinner-wrapper">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else-if=" state.dataRequest?.status === 'deleted'">
      <h1 class="text-center">404: Data Request Deleted</h1>
      <p class="text-bg-dark text-center">This data request has been deleted</p>
    </div>
    <div v-else-if="state.dataRequest?.id">
      <h1 v-if="state.dataRequest?.status !== 'cancelled'">{{ state.dataRequest.title || "Upload Data" }}</h1>
      <h1 v-else><del>{{ state.dataRequest.title || "Upload Data" }}</del> Cancelled</h1>
      <markdown-view v-model="state.dataRequest.description" v-if="state.dataRequest.description"/>
      <ul v-if="state.dataRequest.items.length > 1" class="nav nav-tabs">
        <li v-for="item in state.dataRequest.items" :key="item.id" @click="selectItem(item.id)" class="nav-item">
          <a :class="['nav-link', state.currentItem?.id === item.id ? 'active' : '']" aria-current="page" href="#">
            <span v-if="itemsStatus.get(item.id) === 'completed'">☑</span>
            {{ item.title }}
          </a>
        </li>
      </ul>

      <div v-if="state.currentItem" class="current-item-content">
        <markdown-view v-model="state.currentItem.description" v-if="state.dataRequest.description"/>

        <p v-if="uploadsForItem(state.currentItem.id, 'completed').length > 0">
          Uploaded: {{ uploadsForItem(state.currentItem.id, 'completed').map(u => u.file_name).join(', ') }}
          <a v-if="state.dataRequest?.status !== 'cancelled'" class="btn btn-link" @click="state.userAddsMore = true">Add more</a>
        </p>
        <p v-if="state.dataRequest?.status === 'completed'" class="text-bg-dark text-lg-center">
          This data request has been completed! Feel free to navigate back to AppVetter
        </p>

        <!-- pending -->
        <p v-if="uploadsForItem(state.currentItem.id, 'pending').length > 0">
          Pending: {{ uploadsForItem(state.currentItem.id, 'pending').map(u => u.file_name).join(', ') }}
          <a class="btn btn-sm btn-outline-warning" role="button" @click="deletePending">Delete Pending</a>
        </p>
        <Button type="button" class="me-lg-2" @click="updateDataRequest('completed', state.dataRequest.id)" :disabled="state.dataRequest.status != 'pending'">
          <span class="fa fa-check me-lg-2" aria-hidden="true"></span> Complete Data Request
        </Button>
        <Dashboard
            v-if="state.dataRequest?.status === 'pending' && (itemsStatus.get(state.currentItem.id) === 'pending' || state.userAddsMore)"
            :uppy="uppy"
            :props="{
              width: '100%',
              theme: 'light',
              showLinkToFileUploadResult: false,
              proudlyDisplayPoweredByUppy: false,
              hideProgressAfterFinish: true,
              note: 'You can drag and drop files here or click Browse to select files to upload.',
              metaFields: [
                { id: 'name', name: 'Name', placeholder: 'You can rename the file here' },
                { id: 'description', name: 'Description', placeholder: 'Briefly describe what the file contains' }
              ],
            }"
        />
        <p v-if="state.dataRequest?.status === 'cancelled'" class="text-bg-dark text-lg-center">
          This data request has been cancelled
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAPI } from "@/helpers/services/api"
import { Dashboard } from '@uppy/vue'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

import type { UppyFile } from '@uppy/core'
import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import { onBeforeUnmount, computed, reactive, onMounted, } from "vue";
import { useRoute } from "vue-router";
import type { Upload } from "@/helpers/interface/uploads";
import type {DataRequest, DataRequestItem, UpdateDataRequest} from "@/helpers/interface/trueload";
import MarkdownView from "@/components/Shared/MarkdownView.vue";
import Button from "primevue/button";
import { toast } from "@/helpers/toast";
const { user, isAuthenticated } = useAuth0()
import {counter} from "@fortawesome/fontawesome-svg-core";
import TheHeader from "@/components/TheHeader.vue";
import {useAuth0} from "@auth0/auth0-vue";

const route = useRoute();

const api = useAPI({authGuard: false})

const uploadsForItem = (itemId: string, status: string | null): Array<Upload> => {
  return state.dataRequest.uploads!.filter(
    (upload) => (
      upload.item_id === itemId
      // && upload.status != 'cancelled'
      &&
      (status === null || upload.status === status)
    )
  ) || []
}

const state = reactive({
  dataRequest: {
    id: "",
    customer_id: "",
    title: "",
    description: "",
    items: [],
    status: "",
    request_type: "",
    created_at: "",
    updated_at: "",
    completed_at: "",
    cancelled_at: "",
    uploads: [],
  } as DataRequest,
  dataRequestLoading: false,
  currentItem: null as DataRequestItem | null,
  userAddsMore: false,
});

const itemsStatus = computed(() => {
  const statuses = state.dataRequest.items.map((item) => {
    const pending = uploadsForItem(item.id, 'pending');
    const completed = uploadsForItem(item.id, 'completed');
    const status = completed.length > 0 && pending.length === 0 ? 'completed' : 'pending';
    return [item.id, status] as [string, string]
  })
  return new Map<string, string>(statuses)
})

const getDataRequest = async (id: string) => {
  state.dataRequestLoading = true;
  const response = await api.get(`data-requests/${id}/public`);
  state.dataRequest = response.data;
  state.dataRequestLoading = false;
}

const updateDataRequest = async (status: string, id: string) => {
  state.dataRequestLoading = true;
  const update = {
    status: status
  } as UpdateDataRequest
  const response = await api.patch(`data-requests/${id}`, update)
  toast.success(`Data Request updated! New status is ${status}`)
  state.dataRequest = response.data;
  state.dataRequestLoading = false;
}

const uppy = new Uppy()
uppy.use(AwsS3, {
  async getUploadParameters(file: UppyFile) {
    try {
      const pending = uploadsForItem(state.currentItem!.id, 'pending').find(
        (upload) => (
          upload.file_name === (file.meta?.name || file.name) &&
          upload.file_size === file.size &&
          upload.content_type === (file.meta?.type || file.type)
        )
      )
      if (pending) {
        console.log('reusing pending upload...', pending)
        return pending.upload_url!
      }
      console.log('creating new upload...')
      const resp = await api.post('/data-requests/uploads/', {
        data_request_id: state.dataRequest.id,
        item_id: state.currentItem!.id,
        file_name: file.meta?.name || file.name,
        file_size: file.size,
        content_type: file.meta?.type || file.type,
      })

      const upload: Upload = resp.data
      state.dataRequest.uploads!.push(upload)
      return upload.upload_url!
    } catch (e) {
      console.log(e)
      throw e
    }
  },
})

uppy.on('complete', async (result) => {
  console.log('onComplete', result)
  // failed uploads are cancelled
  for (const file of result.failed) {
    const pendingUpload = state.dataRequest.uploads!.find(
      (upload) => (
        upload.status === 'pending' &&
        upload.file_name === (file.meta?.name || file.name) &&
        upload.file_size === file.size &&
        upload.content_type === (file.meta?.type || file.type)
      )
    )
    console.log(`deleting failed upload:`, pendingUpload)
    if (pendingUpload) {
      await api.delete(`/data-requests/uploads/${pendingUpload.id}`)
    }
  }
  // for each successful upload, mark it as completed
  for (const file of result.successful) {
    const upload = state.dataRequest.uploads!.find(
      (upload) => upload.status === 'pending' && upload.upload_url?.url.startsWith(file.uploadURL)  // uploadURL has no signature
    )
    console.log(`completing upload:`, upload, state.dataRequest.uploads)
    if (upload) {
      await api.post(`/data-requests/uploads/${upload.id}/complete`)
    }
    if (upload) {
      upload.status = 'completed'
    }
  }
  console.log(`refreshing data request...`)
  // fetch the data request again to get the updated status
  state.userAddsMore = false
  await getDataRequest(route.params.data_drop_id as string)

})

onBeforeUnmount(() => {
  uppy.close({ reason: 'unmount' })
  if (state.dataRequest.request_type == 'Paper Applications' && state.dataRequest.status != 'completed') {
    api.patch(`data-requests/${state.dataRequest.id}`, {status: 'cancelled'} as UpdateDataRequest)
  }
})

onMounted(async () => {
  await getDataRequest(route.params.data_drop_id as string);
  const firstPending = state.dataRequest.items.find((item) => itemsStatus.value.get(item.id) === 'pending')
  if (firstPending) {
    selectItem(firstPending.id)
  }
  else{
    selectItem(state.dataRequest.items[0].id)
  }
});

const deletePending = async () => {
  for (const upload of uploadsForItem(state.currentItem!.id, 'pending')) {
    await api.delete(`/data-requests/uploads/${upload.id}`)
  }
  await getDataRequest(route.params.data_drop_id as string)
}

const selectItem = (itemId: string) => {
  if (state.currentItem?.id === itemId) {
    return
  }
  state.currentItem = state.dataRequest.items.find((item) => item.id === itemId) || null
  state.userAddsMore = false
  uppy.cancelAll()
}

</script>

<style scoped>
.current-item-content {
  padding: 1rem;
}
.spinner-wrapper {
  text-align: center;
  margin: 10rem;
}
</style>
@/helpers/interface/trueload