<template>
    <div>
        <label class="config-header">User Preferences</label>
        <div class="row">
            <div class="col-lg-6">
                <form @submit.prevent="" class="my-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-8">
                                    <label for="enableBetaFeatures">Enable beta features</label>
                                    <Checkbox v-model="betaFeatures" binary class="ms-2" @change="enableBetaFeatures"
                                        :inputId="'enableBetaFeatures'" value="on" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { StorageKeyEnum, storageManager } from "@/helpers/storageManager"
import Checkbox from 'primevue/checkbox'
import { toast } from "@/helpers/toast"

const betaFeatures = ref((storageManager.getItem(StorageKeyEnum.EnableBetaFeatures) === "on"))
const enableBetaFeatures = () => {
    storageManager.setItem(StorageKeyEnum.EnableBetaFeatures, betaFeatures.value ? "on" : "off")
    toast.success("Saved!")
}
</script>
