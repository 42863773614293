import { TabulatorFull as Tabulator } from 'tabulator-tables'
import type { Options, AjaxConfig } from 'tabulator-tables'
import { useAuth0 } from '@auth0/auth0-vue'
import { ref } from 'vue'

export const createAjaxConfig = (accessToken: string): AjaxConfig => {
    return {
        method: "POST",
        headers: {
            Authorization: "Bearer " + accessToken
        },
    }
}

/* 
    Create an instance of Tabulator that uses an API endpoint
    as its data source.  
        
        * When using this type of Tabulator, the calling component should 
        destroy it during onUnmounted

        * The ajaxRequesting handler automatically aborts Ajax requests 
        to prevent infinite loops when the calling component unmounts

        * All options are overridable
*/
export const createAjaxTabulator = async (selector: string, options: Options) => {
    if (!document.querySelector(selector)) return null
    const totalCount = ref<number>(0)

    if (!options.ajaxConfig) {
        const { getAccessTokenSilently } = useAuth0()
        const accessToken = await getAccessTokenSilently()
        options.ajaxConfig = createAjaxConfig(accessToken)
    }

    const defaultOptions: any = {
        layout: "fitDataFill",
        ajaxContentType: "json",
        ajaxRequesting: () => {
            // if container doesn't exist anymore, abort pending requests
            if (!document.querySelector(selector))
                return false
        },
        headerFilterLiveFilterDelay: 800,
        paginationSize: 25,
        paginationMode: "remote",
        dataSendParams: {
            "size": "page_size",
        },
        filterMode: "remote",
        sortMode: "remote",
        placeholder: "There are no records found",
        ajaxResponse: (url: string, params: any, response: any) => {
            const getDataCount = response?.data?.length || 0
            const placeholderContainer = document.querySelector(".tabulator-placeholder-content") as HTMLDivElement
            if (placeholderContainer) {
                // hide / show placeholder
                placeholderContainer.style.display = (getDataCount === 0) ? "block" : ""
            }

            if (getDataCount !== 0) {
                // Log the total record count
                totalCount.value = (response?.last_row || 0) as number
            }
            return response;
        },
    }

    // Pagination and progressive load cannot be used at the same time
    if (!options.pagination) {
        defaultOptions.progressiveLoad = "scroll"
        defaultOptions.progressiveLoadScrollMargin = 200
    }
    else {
        defaultOptions.paginationCounter = function (pageSize: any, currentRow: any, currentPage: any, totalRows: any) {
            return customPaginationCounter(pageSize, currentPage, totalRows, totalCount.value)
        }
    }

    // Hide placeholder initially
    const placeholder = `<div class="tabulator-placeholder-content" style="display: none">${options.placeholder || defaultOptions.placeholder}</div>`
    const tabulator = new Tabulator(selector, { ...defaultOptions, ...options, placeholder } as Options)

    tabulator.on("tableBuilt", () => {
        tabulator.on("dataLoading", () => {
            /* display loading alert when pagination triggers fetching more records
             this is done to provide the user feedback that something is happening */
            tabulator.alert("Loading")
        });
        tabulator.on("dataProcessed", () => {
            tabulator.clearAlert()
        })
    })

    return tabulator
}

/* 
    Create an instance of Tabulator that uses a Local data 
    as its data source.  (For non-ajax table)

        * When using this type of Tabulator, the calling component should 
        destroy it during onUnmounted

        * All options are overridable
*/
export const createTabulator = async (selector: string, options: Options, isLoading: boolean = false, paginated: boolean = true) => {
    if (!document.querySelector(selector)) return null
    const totalCount = ref(options?.data?.length || 0)
    const paginationSize = 25
    let defaultOptions: any = {
        layout: "fitDataFill",
        responsiveLayout: true,
        placeholder: "There are no records found",
    }

    if (paginated) {
        const additionalOptions = {
            pagination: "local", // Enable local pagination
            paginationSize: paginationSize, // Default pagination size,
            paginationCounter: function (pageSize: any, currentRow: any, currentPage: any, totalRows: any) {
                return customPaginationCounter(paginationSize, currentPage, totalRows, totalCount.value)
            }
        };

        // Merge the defaultOptions and additionalOptions using the spread operator
        defaultOptions = {
            ...defaultOptions,
            ...additionalOptions,
        };

    }
    const placeholderVisibility = totalCount.value === 0 ? 'inline-block' : 'none'
    const placeholder = `<div class="tabulator-placeholder-content" style="display: ${ placeholderVisibility }">${options.placeholder || defaultOptions.placeholder}</div>`
    const tabulator = new Tabulator(selector, { ...defaultOptions, ...options, placeholder } as Options)
    tabulator.on("tableBuilt", () => {

        // This will mimic the loading alert of the ajaxTabulator
        if (isLoading) tabulator.alert("Loading")
        else tabulator.clearAlert()
    })

    return tabulator
}

export const customPaginationCounter = (pageSize: any, currentPage: any, totalRows: any, totalCount: number) => {
    if (totalRows === 0) return `No results found`

    const startRow = (currentPage - 1) * pageSize + 1;
    const endRow = Math.min(currentPage * pageSize, totalRows);
    const totalRecordCount = totalCount?.toLocaleString() || 0
    totalRows = totalRows?.toLocaleString() || 0
    const resultWord = totalCount > 1 ? "results" : "result"

    if (totalCount === 0) {
        // Default pagination counter
        return `Showing ${startRow} to ${endRow} of ${totalRows} rows`
    }
    else {
        // Display accurate total record count
        return `Showing ${startRow} to ${endRow} of ${totalRecordCount} ${resultWord}`
    }
}
/* 
    Destroy an instance of Tabulator
        
        * This should be called in the component onUnmounted event
        * Destroying it ensures all resources are released
*/
export const destroyTabulator = (tabulator: Tabulator | null) => {
    if (tabulator) {
        tabulator.destroy()
        tabulator = null
    }
}
