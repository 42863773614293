<template>
  <main class="p-2">
    <!-- Dropdown to select page -->
    <select @change="handlePageChange" class="mb-3">
      <option value="onboardingLanding">Onboarding</option>
      <option value="clientConfigs">Configs</option>
      <option value="clientIngestions">Ingestions</option>
      <option value="dataRequests">Data Requests</option>
    </select>

    <!-- Render selected component -->
    <component :is="selectedPageComponent" />
  </main>
</template>
<script setup lang="ts">
import { shallowRef } from 'vue';

import onboardingLanding from '@/components/Admin/Onboarding/Onboarding-landing.vue';
import clientConfigs from '@/components/Admin/Onboarding/Onboarding-configsList.vue';
import clientIngestions from '@/components/Admin/Onboarding/Onboarding-ingestionsList.vue';
import dataRequests from '@/components/Admin/Onboarding/Onboarding-dataRequestList.vue';
// Define a reactive variable to hold the selected page component
const selectedPageComponent = shallowRef(onboardingLanding); // Set default component

// Method to handle page change
const handlePageChange = (event: Event) => {
  const target = event.target as HTMLSelectElement | null;
  if (target) {
    const selectedPage = target.value;
    switch (selectedPage) {
      case 'onboardingLanding':
        selectedPageComponent.value = onboardingLanding;
        break;
      case 'clientConfigs':
        selectedPageComponent.value = clientConfigs;
        break;
      case 'clientIngestions':
        selectedPageComponent.value = clientIngestions;
        break;
      case 'dataRequests':
        selectedPageComponent.value = dataRequests;
        break;

      default:
        selectedPageComponent.value = onboardingLanding; // Set default component or handle error
        break;
    }
  }
};
</script>
