<template>
    <div class="element-container">
        <label for="reason" class="form-label form-label-required">Reason</label>
        <Select id="reason" :scrollHeight="PV_SCROLL_HEIGHT" v-model="reasons" :options="reasonsDropdown" placeholder="-- Select --" class="d-flex" />
    </div>

    <div class="element-container">
        <label for="unqualifiedStartYear" class="form-label form-label-required">Unqualified Start Year</label>
        <Select id="unqualifiedStartYear" :scrollHeight="PV_SCROLL_HEIGHT" v-model="unqualifiedStartYear" :options="unqualifiedDropdown"
            placeholder="-- Select --" class="d-flex" />
    </div>

    <div class="element-container">
        <label for="lienOrBackTaxes" class="form-label form-label-required">Lien or Back Taxes</label>
        <Select id="lienOrBackTaxes" :scrollHeight="PV_SCROLL_HEIGHT" v-model="lienOrBackTaxes" :options="lienOrBackTaxesOptions"
            placeholder="-- Select --" class="d-flex" />
    </div>

    <div class="element-container">
        <label for="mailingTemplate" class="form-label">Mailing Template</label>
        <Select id="mailingTemplate" :scrollHeight="PV_SCROLL_HEIGHT" v-model="mailingTemplate" :options="mailingTemplateDropdown" optionLabel="name"
            optionValue="id" placeholder="-- Select --" class="d-flex" />
    </div>

    <div class="element-container" v-if="totalCandidateSelected === 1">
        <label for="recoveredAmount" class="form-label form-label-required">Estimated Recovered Amount</label>
        <InputNumber v-model="recoveredAmount" inputId="recoveredAmount" fluid class="d-flex w-100" />
    </div>

    <div v-if="lienOrBackTaxes === 'Yes'">
        <div class="element-container">
            <label for="backTaxLienAmount" class="form-label">Back Tax / Lien Amount</label>
            <InputNumber v-model="backTaxLienAmount" inputId="backTaxLienAmount" fluid class="d-flex w-100" />
        </div>
    </div>

    <div class="element-container">
        <label for="addNote" class="form-label" :class="{ 'form-label-required': reasons?.toLowerCase() === 'other' }">
            Add Note
        </label>
        <Textarea v-model="optionalNote" :maxlength="MAX_NOTE_LENGTH" class="d-flex" />
    </div>

</template>

<script setup lang="ts">
import { MAX_NOTE_LENGTH, PV_SCROLL_HEIGHT } from '@/helpers/common'
import Textarea from 'primevue/textarea'
import Select from 'primevue/select'
import InputNumber from 'primevue/inputnumber'
import { ref, watch } from 'vue'
import dayjs from 'dayjs'

const reasons = defineModel<string | null>("reasons")
const unqualifiedStartYear = defineModel<string | null>("unqualifiedStartYear")
const lienOrBackTaxes = defineModel<string | null>("lienOrBackTaxes")
const mailingTemplate = defineModel<number | null>("mailingTemplate")
const backTaxLienAmount = defineModel<number | null>("backTaxLienAmount")
const recoveredAmount = defineModel<number | null>("recoveredAmount")
const optionalNote = defineModel<string | null>("optionalNote")

const { reasonsDropdown, mailingTemplateDropdown, startYear, totalCandidateSelected } = defineProps({
    reasonsDropdown: {
        required: true
    },
    mailingTemplateDropdown: {
        required: true
    },
    startYear: {
        default: 2009,
        type: Number
    },
    totalCandidateSelected: {
        type: Number
    }
})

const unqualifiedDropdown = ref<string[]>([])
const currentYear: number = dayjs().year()
unqualifiedDropdown.value = []
for (let index = (currentYear + 1); index >= startYear; index--) {
    unqualifiedDropdown.value.push(index.toString());
}

const lienOrBackTaxesOptions = ["Yes", "No"]

const addNote = ref<boolean>(false)
watch(() => reasons.value, (newValue) => {
    if (newValue?.toLowerCase() === "other") addNote.value = true
})
</script>