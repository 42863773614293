<template>
    <a @click="props.clickHandler(props.id)" href="javascript:;" 
        v-tooltip.top="'Additional Details'" aria-label="Additional Details"
        class="list-details-button drawer-activator">
        <i class="fa fa-xl fa-circle-info"></i>
    </a>
</template>

<script setup lang="ts">
import type { PropType } from "vue"

const props = defineProps({
    id: {
        required: true
    },
    clickHandler: {
        type: Function as PropType<(id: any) => void>,
        required: true
    }
})

</script>