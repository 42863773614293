import axios from "axios"
import { useAuth0 } from "@auth0/auth0-vue";

export const useAPI = (opts = { authGuard: true }) => {
    axios.defaults.baseURL = import.meta.env.VITE_DEV_API_URL as string
    const publicPaths = ["apply"]
    const currentRouteSegments = location.pathname.split('/')
    if (!publicPaths.includes(currentRouteSegments?.[1])) {
        const { getAccessTokenSilently } = useAuth0();

        // Add auth bearer token to the headers
        axios.interceptors.request.use(async (config) => {
            try {
                const accessToken = await getAccessTokenSilently()
                config.headers["Authorization"] = `Bearer ${accessToken}`
            } catch (error) {
                if (opts.authGuard) {
                    throw error
                }
            }
            return config
        }, (error) => {
            return Promise.reject(error);
        });
    }

    return axios
}
