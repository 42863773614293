import { ref, computed } from "vue"
import { defineStore } from "pinia";
import { useAPI } from "@/helpers/services/api"


interface CsvColumnInfo {
    label: string | null
    data_sample: string[]
}

interface TableDataInfo {
    columns: CsvColumnInfo[]
    pandas_kwargs: any
}

type S3KeyInfo = {
    uri: string
    info: TableDataInfo
}


export const useConfigEditorCache = defineStore("configEditorCache", () => {
    const s3KeyInfoCache = ref([] as S3KeyInfo[])
    const getTableDataInfo = computed(() => {
        return (s3Uri: string) => {
            const info = s3KeyInfoCache.value.find((item) => item.uri === s3Uri)
            if (info) {
                return info.info
            } else {
                const that = this
                fetchS3KeyInference(s3Uri).then(
                    (info: TableDataInfo) => {
                        console.log({
                            "event": "new info arrived",
                            "uri": s3Uri,
                            "info": info,
                            this: this,
                            that
                        })
                        s3KeyInfoCache.value.push({ uri: s3Uri, info })
                    }
                )
            }
            return null
        }
    })
    const api = useAPI()

    const fetchS3KeyInference = async (uri: string): Promise<TableDataInfo> => {
        const response = await api.get(`/onboarding/s3-key-inference`, { params: { uri } })
        return response.data
    }
    return {
        s3KeyInfoCache,
        fetchS3KeyInference,
        getTableDataInfo,
    }
})
